import React from 'react'
import {EyeOutlined, PlusOutlined, SettingOutlined} from '@ant-design/icons'
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil'
import {activeTabSelector, isEnabledRefreshingAtom} from '../states/settingState'
import {Button, message, Select} from 'antd'
import AddingModalComponent from './AddingModal'
import {isShowAddingModalAtom} from '../states/appState'
import TextLogoComponent from './TextLogo'
import {cexAtom, dexAtom, exchangeConfigAtom, exchangeKeySelector} from '../states/exchangeState'
import {accessTokenSelector, authUserSelector, expireTimestampSelector} from '../states/authState'
import dayjs from 'dayjs'

const HeaderComponent = () => {

  const [activeTab, setActiveTable] = useRecoilState(activeTabSelector)
  const isEnabledRefreshing = useRecoilValue(isEnabledRefreshingAtom)
  const [isShowAddingModal, setIsShowAddingModal] = useRecoilState(isShowAddingModalAtom)
  const exchangeKey = useRecoilValue(exchangeKeySelector)
  const exchanges = useRecoilValue(exchangeConfigAtom)
  const [[cex, setCex], [dex, setDex]] = [useRecoilState(cexAtom), useRecoilState(dexAtom)]
  const authUser = useRecoilValue(authUserSelector)
  const setAccessToken = useSetRecoilState(accessTokenSelector)
  const setExpireTimestamp = useSetRecoilState(expireTimestampSelector)

  const TabComponent = () => {
    const configItems = [
      {buttonName: '监控表', icon: (<EyeOutlined/>), value: 'monitor'},
      {buttonName: '控制台', icon: (<SettingOutlined/>), value: 'setting'},
    ]

    const baseBtnClassName = 'bg-[unset] border-none inline-flex flex-row justify-between items-center rounded bg-gradient-to-r from-[rgba(88,7,255,1)] px-4 py-2 transition-all shadow-md duration-200 hover:bg-indigo-600'
    return (
      <>
        {configItems.map((item) => (
          <Button
            type="primary"
            icon={item.icon}
            key={item.buttonName}
            size="middle"
            className={`${baseBtnClassName} ${item.value === activeTab ? 'opacity-100 to-blue-600' : 'opacity-45 via-[rgba(79,32,163,0)] to-[transparent]'}`}
            onClick={(e) => setActiveTable(item.value)}>
            {item.buttonName}
          </Button>
        ))}
        {(!isEnabledRefreshing && authUser.type === 'master') && (
          <Button
            type="primary"
            icon={<PlusOutlined/>}
            size="middle"
            className={`${baseBtnClassName} opacity-45 via-[rgba(79,32,163,0)] to-[transparent]`}
            onClick={() => {
              setIsShowAddingModal(true)
            }}
          >
            监控项
          </Button>
        )}
      </>
    )
  }

  const ExchangeSelectorComponent = (p) => {
    const {Option} = Select
    const changeExchange = (value) => {
      const [cex, dex] = value.split('&')
      message.info(`监控交易所切换为 ${cex}&${dex}`)
      setCex(cex)
      setDex(dex)
    }

    return (
      <Select defaultValue={exchangeKey} onChange={changeExchange} disabled={p.disabled}>
        {exchanges.map((item) => (
          <Option key={`${item.cex}&${item.dex}`} value={`${item.cex}&${item.dex}`}>
            {String(item.cex).toUpperCase()} & {String(item.dex).toUpperCase()}
          </Option>
        ))}
      </Select>
    )

  }

  const UserCenterComponent = () => (
    <div className="group inline-flex text-white border-2 border-[rgba(255,255,255,.2)] px-4 py-1 relative rounded cursor-default">
      <div className="group-hover:hidden">{authUser.username}</div>
      <div className="group-hover:block hidden cursor-pointer" onClick={() => {
        message.info(authUser.username + '退出登录')
        setAccessToken(null)
        setExpireTimestamp(null)
      }}>点击注销</div>
      <div className="hidden group-hover:block space-y-2 bg-[rgba(0,0,0,.4)] p-2 absolute top-10 right-0 min-w-[280px]">
        <div>
            用户权限：{authUser.type === 'view' ? '浏览权限' : '管理权限'}
        </div>
        <div>
            过期时间：{dayjs(authUser.exp * 1000).format('YYYY-MM-DD HH:mm:ss')}
        </div>
      </div>
    </div>
  )

  return (
    <>
      <div
        className="flex flex-row flex-nowrap justify-between items-center <lg:rounded-md <lg:space-y-6 <lg:flex-wrap <lg:flex-col">
        <TextLogoComponent>
          <ExchangeSelectorComponent disabled={!!isEnabledRefreshing}/>
        </TextLogoComponent>
        <div className="text-white space-x-2">
          <TabComponent/>
          <UserCenterComponent/>
        </div>
      </div>
      {isShowAddingModal && (<AddingModalComponent/>)}
    </>
  )
}

export default HeaderComponent
