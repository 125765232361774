import React from 'react'
import TextLogoComponent from './TextLogo'

const FullScreenContentComponent = (props) => (
  <>
    <div
      className="App p-6 bg-gradient-to-br from-[rgba(83,0,179,1)] via-[rgba(0,3,131,0.996078431372549)] to-[rgba(26,0,42,0.8705882352941177)] min-h-[100vh] flex flex-row justify-between items-center">
      <div className="mx-auto space-y-6 w-[400px] bg-[rgba(255,255,255,.1)] p-4 transition-all shadow-md duration-200 hover:shadow-xl hover:p-6">
        <TextLogoComponent />
        <div className="mt-10 space-y-4">
          {props.children}
        </div>
      </div>
    </div>
  </>
)

export default FullScreenContentComponent
