import fly from 'flyio'

fly.config.timeout = 10000
fly.config.headers = {
  'accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9'
}
fly.interceptors.request.use((request) => request)
fly.interceptors.response.use(
  (response) =>
    response.data
  ,
  (err) => {
    console.log(err)
  }
)

export default fly
