import React from 'react'
import {useRecoilValue} from 'recoil'
import HeaderComponent from './Header'
import SettingPanelComponent from './SettingPanel'
import {activeTabSelector} from '../states/settingState'
import MonitorTableComponent from './MonitorTable'
import NotificationAudioComponent from './NotificationAudio'

const MainComponent = (props) => (
  <>
    <div
      className="App p-6 bg-gradient-to-br from-[rgba(83,0,179,1)] via-[rgba(0,3,131,0.996078431372549)] to-[rgba(26,0,42,0.8705882352941177)] min-h-[100vh]">
      <div className="mx-auto space-y-6">
        <HeaderComponent/>
        <SettingPanelComponent/>
        {useRecoilValue(activeTabSelector) === 'monitor' && <MonitorTableComponent tokens={props.tokens} />}
        <NotificationAudioComponent/>
      </div>
    </div>
  </>
)

export default MainComponent
