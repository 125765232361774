import Dexie from 'dexie'
import {message} from 'antd'
import React from 'react'

const DB_NAME = 'CoinMonitor'
const DB_SCHEMA = {
  logs: '++id,event,text,datetime',
  settings: '&key,value',
  notifies: '++id,direction,cex,dex,symbol,notifyValue,text,datetime',
  // monitors: '&symbol,cex,dex,token,buyOnePriceOfCex,sellPriceOfDex, formulaA, sellOnePriceOfCex, buyOnePriceOfDex, formulaB, updateTime, usdtAmount, decimals, latestNotifyUnixTime, status'
}
const DB = new Dexie(DB_NAME)

const initDB = () => {
  const cancelLoading = message.loading('浏览器数据库服务加载中...', 0)
  try {
    DB.version(1).stores(DB_SCHEMA)
    message.success('加载浏览器数据库服务成功')
    cancelLoading()
  } catch (e) {
    message.error('加载浏览器数据库服务失败')
  }
}

initDB()

export default DB
