import React, {useState} from 'react'
import {useRecoilState, useRecoilValue} from 'recoil'
import {ethAtom, gasAtom, launchTimeAtom, runningTimeSelector} from '../states/appState'
import fly from '../plugins/flyioPlugin'
import {serverApiURI, wsURI} from '../config/apiConfig'
import {useInterval} from 'ahooks'
import {Tooltip} from 'antd'
import {accessTokenSelector} from '../states/authState'
import {cexAtom, dexAtom, modeSelector} from '../states/exchangeState'
import {isEnabledRefreshingAtom, isWsRunningAtom, usdtAmountSelector} from '../states/settingState'

const InfoBarComponent = (props) => {

  const [serverStatus, setServerStatus] = useState(null)
  const [cex, dex, mode] = [useRecoilValue(cexAtom), useRecoilValue(dexAtom), useRecoilValue(modeSelector)]
  const [usdtAmount, setUsdtAmount] = useRecoilState(usdtAmountSelector)
  const [isWsRunning, setIsWsRunning] = useRecoilState(isWsRunningAtom)
  const [isEnabledRefreshing, setIsEnabledRefreshing] = useRecoilState(isEnabledRefreshingAtom)

  const statisticsItems = [
    {name: '监控项', value: props.monitorCount || '-', tooltip: '当前运行的监控项数量'},
    {name: '启动时间', value: useRecoilValue(launchTimeAtom), tooltip: '本次服务开启时间'},
    {name: '运行时间', value: useRecoilValue(runningTimeSelector), tooltip: '页面停留时间'},
  ]

  const [gas, eth] = [useRecoilValue(gasAtom), useRecoilValue(ethAtom)]
  const accessToken = useRecoilValue(accessTokenSelector)

  gas && statisticsItems.push({name: 'FastGasPrice', value: gas.FastGasPrice, tooltip: 'FastGasPrice参考值'})
  eth && statisticsItems.push({name: 'EthPrice', value: eth, tooltip: 'EthPrice参考值'})
  serverStatus && statisticsItems.push({
    name: '服务端运行数', value: serverStatus.max, tooltip: (
      <>
        <div>中心服务主机：{serverStatus.hostname}</div>
        {serverStatus.clients && serverStatus.clients.map((item) => (<div key={item.hostname}>{item.hostname}：{item.workers}</div>))}
      </>
    )
  })

  const requestServerStatus = async () => {
    const url = (mode === 'ws' ? wsURI : serverApiURI) + '/api/status'
    const {data: {hostname, max, clients, workers = {}}} = await fly.request(url, {
      accessToken
    }, {
      method: 'GET',
      parseJson: true,
    })

    const key = `${cex}/${dex}`
    if (workers[key]) {
      if (workers[key].amount) {
        setUsdtAmount(workers[key].amount)
        setIsWsRunning(true)
        // setIsEnabledRefreshing(true)
      }
    } else {
      setIsWsRunning(false)
    }
    setServerStatus({
      max,
      hostname,
      clients: clients && Reflect.ownKeys(clients).map((key) => ({hostname: key, workers: clients[key].workers}))
    })
  }

  useInterval(() => {
    requestServerStatus()
  }, 5 * 1000, {immediate: true})

  return (
    <>
      <div
        className="px-4 py-2 flex flex-row flex-nowrap justify-between items-center bg-[rgba(255,255,255,.1)] transition duration-200 hover:bg-opacity-30 <xl:flex-wrap <xl:space-y-4">
        <div className="text-white divide-x divide-indigo-600 flex flex-row justify-center">
          {statisticsItems.map((item) =>
            <Tooltip key={item.name} placement="topLeft" title={item.tooltip}>
              <div className="w-[fit-content] px-4">
                {item.name} <span className="font-600 text-md">{item.value}</span>
              </div>
            </Tooltip>
          )}
        </div>
        {props.children && (
          <div>
            {props.children}
          </div>
        )}
      </div>
    </>
  )

}

export default InfoBarComponent
