import React, {useEffect, useMemo, useRef, useState} from 'react'
import {Checkbox, Col, message, Row, Switch, Table, Tag, Tooltip} from 'antd'
import {getDecimalsOfNumber} from '../plugins/mathPlugin'
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil'
import {isShowMonitorDetailModalAtom, latestNotifyTimestampAtom, systemModeAtom} from '../states/appState'
import {usdtToken} from '../config/coinConfig'
import fly from '../plugins/flyioPlugin'
import {serverApiURI} from '../config/apiConfig'
import {
  blockedSymbolsSelector,
  blockedValueOfFormulaASelector,
  blockedValueOfFormulaBSelector,
  cexBalanceAmountSelector,
  enableBlockedSymbolDepositSelector,
  enableBlockedNoBalanceSelector,
  enableBlockedSymbolWithdrawalSelector,
  enableBlockedValueOfFormulaASelector,
  enableBlockedValueOfFormulaBSelector,
  isEnabledNotifyingSelector,
  isWatchedCexToDexSelector,
  isWatchedDexToCexSelector,
  notifyValueOfFormulaASelector,
  notifyValueOfFormulaBSelector,
  tbAddressAtom,
} from '../states/settingState'
import dayjs from '../plugins/dayjsPlugin'
import copy from 'copy-to-clipboard'
import MonitorDetailModalComponent from './MonitorDetailModal'
import {cexAtom, dexAtom} from '../states/exchangeState'
import {DownOutlined, PlusCircleOutlined, UpOutlined, WalletOutlined} from '@ant-design/icons'
import InfoBarComponent from './InfoBar'
import WithdrawBar from './WithdrawBar'
import { accessTokenSelector } from '../states/authState'

const TableComponent = (props) => {
  const ref = useRef()

  const [cex, dex] = [useRecoilValue(cexAtom), useRecoilValue(dexAtom)]
  const tbAddress = useRecoilValue(tbAddressAtom)
  const cexBalanceAmount = useRecoilValue(cexBalanceAmountSelector)
  const isEnabledNotifying = useRecoilValue(isEnabledNotifyingSelector)
  const isWatchedDexToCex = useRecoilValue(isWatchedDexToCexSelector)
  const isWatchedCexToDex = useRecoilValue(isWatchedCexToDexSelector)
  const [isShowMonitorDetailModal, setIsShowMonitorDetailModalAtom] = useRecoilState(isShowMonitorDetailModalAtom)
  const [activeMonitorItem, setActiveMonitorItem] = useState({})
  const [isShowColumnFilter, setIsShowColumnFilter] = useState(false)
  const [isShowSymbolFilter, setIsShowSymbolFilter] = useState(false)
  const [systemMode, setSystemMode] = useRecoilState(systemModeAtom)
  const [blockedSymbols, setBlockedSymbols] = useRecoilState(blockedSymbolsSelector)
  const [notifyValueOfFormulaA, notifyValueOfFormulaB] = [useRecoilValue(notifyValueOfFormulaASelector), useRecoilValue(notifyValueOfFormulaBSelector)]
  const [blockedSymbolsCheckAll, setBlockedSymbolsCheckAll] = useState(false)
  const [blockedValueOfFormulaA, blockedValueOfFormulaB] = [useRecoilValue(blockedValueOfFormulaASelector), useRecoilValue(blockedValueOfFormulaBSelector)]
  const [enableBlockedValueOfFormulaA, setEnableBlockedValueOfFormulaA] = useRecoilState(enableBlockedValueOfFormulaASelector)
  const [enableBlockedValueOfFormulaB, setEnableBlockedValueOfFormulaB] = useRecoilState(enableBlockedValueOfFormulaBSelector)
  const [enableBlockedSymbolsWithdrawal, setEnableBlockedSymbolsWithdrawal] = useRecoilState(enableBlockedSymbolWithdrawalSelector)
  const [enableBlockedNoBalance, setEnableBlockedNoBalance] = useRecoilState(enableBlockedNoBalanceSelector)
  const [enableBlockedSymbolsDeposit, setEnableBlockedSymbolsDeposit] = useRecoilState(enableBlockedSymbolDepositSelector)
  const setLatestNotifyTimestamp = useSetRecoilState(latestNotifyTimestampAtom)
  const accessToken = useRecoilValue(accessTokenSelector)

  const originTableData = props.tokens || []
  const tableData = useMemo(
    () => (props.tokens || []),
    [props.tokens]
  )

  const onChangeMode = (performance) => {
    setSystemMode(performance)
    setEnableBlockedValueOfFormulaA(!!performance)
    setEnableBlockedValueOfFormulaB(!!performance)
    setEnableBlockedSymbolsWithdrawal(!!performance)
    setEnableBlockedSymbolsDeposit(!!performance)
  }

  const withdrawal = async (token) => {
    const cfm = `确认提币：${token.symbol}`
    if (confirm(cfm)) {
      message.info('发起提币请求')
      try {
        const response = await fly.request(serverApiURI + '/api/withdrawal', {
          symbol: token.symbol,
          cex,
          auth: accessToken,
          withdraw_address: tbAddress
        }, {
          method: 'GET',
          parseJson: true,
        })
        if (response.code === -1 || response.error) {
          throw new Error(response.error)
        }
        message.success(`提币成功: ${response.data.amount} ${token.symbol}`)
      } catch (e) {
        message.error(`提币失败:${e.message}`)
      }
    }
  }

  const table1Columns = [
    {
      title: '货币标识',
      dataIndex: 'symbol',
      key: 'symbol',
      fixed: 'left',
      width: 120,
      sorter: (a, b) => {
        let stringA = a.symbol.toUpperCase()
        let stringB = b.symbol.toUpperCase()
        if (stringA < stringB) {
          return -1
        }
        if (stringA > stringB) {
          return 1
        }
        return 0
      },
      render: (text, row, index) => (
        <div className="flex flex-row flex-nowrap items-center space-x-2 cursor-pointer" onClick={() => {
          setActiveMonitorItem(row)
          setIsShowMonitorDetailModalAtom(true)
        }}>
          {row.logo && (<div><img src={row.logo} className="w-8 f-8"/></div>)}
          <div>{text} {row.tag && <Tag color="transparent" style={{border: '1px solid white'}}>{row.tag}</Tag>}</div>
        </div>
      ),
    },
    // {
    //   title: 'usdt总额',
    //   dataIndex: 'usdtAmount',
    //   key: 'usdtAmount',
    //   width: 100,
    //   render: (text, row, index) => (
    //     text || '-'
    //   ),
    // },
    {
      title: `${cex}买1价`,
      dataIndex: 'buyOnePriceOfCex',
      key: 'buyOnePriceOfCex',
      width: 160,
      sorter: (a, b) => a.buyOnePriceOfCex - b.buyOnePriceOfCex,
      // render: (text, row, index) => {
      //   const link = `https://www.huobi.com/zh-cn/exchange/${String(row.symbol).toLowerCase()}_usdt`
      //   return <a href={link} target="blank">{text || '-'}</a>
      // },
      render: (text, row, index) => (
        <span onClick={() => {
          copy(text)
          message.info(`已复制 ${text}`)
        }} className="cursor-pointer">{text}</span>
      )
    },
    {
      title: `${dex}卖1价`,
      dataIndex: 'sellPriceOfDex',
      key: 'sellPriceOfDex',
      width: 160,
      sorter: (a, b) => a.sellPriceOfDex - b.sellPriceOfDex,
      render: (text, row, index) => {
        const link = `https://app.1inch.io/#/1/classic/swap/${row.token}/${usdtToken}`
        const decimals = getDecimalsOfNumber(row.buyOnePriceOfCex)
        return <a href={link} target="blank">{Number(text).toFixed(decimals) || '-'}</a>
      }
    },
    {
      title: '公式A',
      dataIndex: 'formulaA',
      key: 'formulaA',
      width: 160,
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.formulaA - b.formulaA,
      render: (text, row, index) => <div className="cursor-pointer">
        <Tag onClick={() => {
          copy(row.token)
          message.info(`已复制 ${row.symbol} 合约地址`)
        }} color={text >= notifyValueOfFormulaA ? '#f50' : 'transparent'}>{Number(text).toFixed(4)} </Tag>
        <span onClick={() => {
          if (!window.$CoinMonitor) {
            return message.info('未安装扩展程序')
          }
          window.$CoinMonitor.swap('A', cex, dex, row)
          ref.current.setSymbol(row.symbol)
        }} style={{float: 'right'}}>
          <PlusCircleOutlined />
        </span>
      </div>
    },
    {
      title: '最近更新',
      dataIndex: 'updateTime',
      key: 'updateTime',
      width: 120,
      sorter: (a, b) => a.updateTime - b.updateTime,
      render: (text, row, index) => {
        if (text) {
          return (
            <Tooltip title={`${text} / ${dayjs(text).fromNow(true)}`}>
              {dayjs(text).format('HH:mm:ss')}
            </Tooltip>
          )
        }
        return '-'
      }
    },
    // {
    //   title: '最近报警',
    //   dataIndex: 'latestNotifyUnixTime',
    //   key: 'latestNotifyUnixTime',
    //   width: 180,
    //   sorter: (a, b) => a.latestNotifyUnixTime - b.latestNotifyUnixTime,
    //   render: (text, row, index) => {
    //     const time = dayjs.unix(text)
    //     return (
    //       <Tooltip title={`${time.format('YYYY-MM-DD HH:mm:ss')} / ${time.fromNow(true)}`}>
    //         {text ? time.format('HH:mm:ss') : '-'}
    //       </Tooltip>
    //     )
    //   }
    // }
    // {
    //   title: 'GasFeeA',
    //   dataIndex: 'gasFeeA',
    //   key: 'gasFeeA',
    //   width: 160,
    //   sorter: (a, b) => a.gasFeeA - b.gasFeeA,
    //   render: (text, row, index) => (gas && eth) ? Number((((gas.FastGasPrice + 5) * row.gasFeeOfSell) / 10000000000) * eth).toFixed(0) : '-'
    // },
  ]
  const table2Columns = [
    {
      title: '货币标识',
      dataIndex: 'symbol',
      key: 'symbol',
      fixed: 'left',
      width: 120,
      sorter: (a, b) => {
        let stringA = a.symbol.toUpperCase()
        let stringB = b.symbol.toUpperCase()
        if (stringA < stringB) {
          return -1
        }
        if (stringA > stringB) {
          return 1
        }
        return 0
      },
      render: (text, row, index) => (
        <div className="flex flex-row flex-nowrap items-center space-x-2 cursor-pointer" onClick={() => {
          setActiveMonitorItem(row)
          setIsShowMonitorDetailModalAtom(true)
        }}>
          {row.logo && (<div><img src={row.logo} className="w-8 f-8"/></div>)}
          <div>{text}</div>
        </div>
      ),
    },
    {
      title: `${dex}买1价`,
      dataIndex: 'buyOnePriceOfDex',
      key: 'buyOnePriceOfDex',
      width: 160,
      sorter: (a, b) => a.buyOnePriceOfDex - b.buyOnePriceOfDex,
      render: (text, row, index) => {
        const link = `https://app.1inch.io/#/1/classic/swap/${usdtToken}/${row.token}`
        const decimals = getDecimalsOfNumber(row.sellOnePriceOfCex)
        return <a href={link} target="blank">{Number(text).toFixed(decimals) || '-'}</a>
      }
    },
    {
      title: `${cex}卖1价`,
      dataIndex: 'sellOnePriceOfCex',
      key: 'sellOnePriceOfCex',
      width: 160,
      sorter: (a, b) => a.sellOnePriceOfCex - b.sellOnePriceOfCex,
      // render: (text, row, index) => {
      //   const link = `https://www.huobi.com/zh-cn/exchange/${String(row.symbol).toLowerCase()}_usdt`
      //   return <a href={link} target="blank">{text || '-'}</a>
      // }
      render: (text, row, index) => (
        <span onClick={() => {
          copy(text)
          message.info(`已复制 ${text}`)
        }} className="cursor-pointer">{text}</span>
      )
    },
    {
      title: '公式B',
      dataIndex: 'formulaB',
      key: 'formulaB',
      width: 160,
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.formulaB - b.formulaB,
      render: (text, row, index) => <div className="cursor-pointer">
        <Tag onClick={() => {
          copy(row.token)
          message.info(`已复制 ${row.symbol} 合约地址`)
        }} color={text >= notifyValueOfFormulaB ? '#f50' : 'transparent'}>{Number(text).toFixed(4)} </Tag>
        <span onClick={() => {
          if (!window.$CoinMonitor) {
            return message.info('未安装扩展程序')
          }
          window.$CoinMonitor.swap('B', cex, dex, row)
        }} style={{float: 'right'}}>
          <PlusCircleOutlined />
        </span>
      </div>
    },
    // {
    //   title: 'GasFeeB',
    //   dataIndex: 'gasFeeB',
    //   key: 'gasFeeB',
    //   width: 160,
    //   sorter: (a, b) => a.gasFeeOfSell - b.gasFeeOfSell,
    //   render: (text, row, index) => gas ? Number((((gas.FastGasPrice + 5) * row.gasFeeOfBuy) / 10000000000) * eth).toFixed(0) : '-'
    // },
    {
      title: '最近更新',
      dataIndex: 'updateTime',
      key: 'updateTime',
      width: 120,
      sorter: (a, b) => a.updateTime - b.updateTime,
      render: (text, row, index) => {
        if (text) {
          return (
            <Tooltip title={`${text} / ${dayjs(text).fromNow(true)}`}>
              {dayjs(text).format('HH:mm:ss')}
            </Tooltip>
          )
        }
        return '-'
      }
    },
    // {
    //   title: '最近报警',
    //   dataIndex: 'latestNotifyUnixTime',
    //   key: 'latestNotifyUnixTime',
    //   width: 180,
    //   sorter: (a, b) => a.latestNotifyUnixTime - b.latestNotifyUnixTime,
    //   render: (text, row, index) => {
    //     const time = dayjs.unix(text)
    //     return (
    //       <Tooltip title={`${time.format('YYYY-MM-DD HH:mm:ss')} / ${time.fromNow(true)}`}>
    //         {text ? time.format('HH:mm:ss') : '-'}
    //       </Tooltip>
    //     )
    //   }
    // }
  ]

  const tableDataA = useMemo(() => tableData.filter((item) => {
    const ruleResults = [!blockedSymbols.includes(item.key)]
    if (enableBlockedValueOfFormulaA) {
      ruleResults.push(item.formulaA >= blockedValueOfFormulaA)
    }
    if (enableBlockedSymbolsWithdrawal) {
      item.status && ruleResults.push(item.status.withdrawal === 'open')
    }
    if (enableBlockedNoBalance) {
      ruleResults.push(item.balanceState ? !!item.balanceSufficient : true)
    }
    return ruleResults.reduce((current, next) => current && next) && item.direction === 'sell'
  }), [blockedSymbols, blockedValueOfFormulaA, enableBlockedNoBalance, enableBlockedSymbolsWithdrawal, enableBlockedValueOfFormulaA, tableData])
  const tableDataB = useMemo(() => tableData.filter((item) => {
    const ruleResults = [!blockedSymbols.includes(item.key)]
    if (enableBlockedValueOfFormulaB) {
      ruleResults.push(item.formulaB >= blockedValueOfFormulaB)
    }
    if (enableBlockedSymbolsDeposit) {
      item.status && ruleResults.push(item.status.deposit === 'open')
    }
    return ruleResults.reduce((current, next) => current && next) && item.direction === 'buy'
  }), [blockedSymbols, blockedValueOfFormulaB, enableBlockedSymbolsDeposit, enableBlockedValueOfFormulaB, tableData])

  if (cex === 'binance' || cex === 'gate' || cex === 'mxc' || cex === 'kucoin' || cex === 'huobi') {
    const column1 = {
      title: '提状态',
      dataIndex: 'status',
      key: 'status',
      width: 120,
      sorter: (a, b) => {
        const A = a.status.withdrawal === 'open'
        const B = b.status.withdrawal === 'open'
        return A > B ? -1 : 1
      },
      render: (text, row, index) => {
        if (!row.status || Reflect.ownKeys(row.status).length === 0) {
          return '-'
        }
        // const check = cexBalanceAmount ? parseFloat(cexBalanceAmount) : parseFloat(row.usdtAmount)
        const balanceState = row.balanceState
        const balanceSufficient = row.balanceSufficient

        return (
          <div className="opacity-50 cursor-default hover:opacity-100">
            <Tag onClick={() => {
              copy(row.token)
              message.info(`已复制 ${row.token}`)
            }} className="cursor-pointer" color={row.status.withdrawal === 'open' ? '#2db7f5' : '#f50'}>
              提 {row.status.withdrawal === 'open'
                ? '√'
                : (row.status.withdrawal === 'close' ? '×' : '-')}
            </Tag>
            {balanceState && <Tag onClick={() => {
              message.info(`余额 $${row.cexBalanceValue}`)
            }} className="cursor-pointer" color={balanceSufficient ? '#2db7f5' : '#f50'}>
              余 {balanceSufficient ? '√' : 'x'}
            </Tag>}
            <span onClick={() => {
              withdrawal(row)
            }} style={{float: 'right'}}>
              <WalletOutlined />
            </span>
          </div>
        )
      },
    }
    const column2 = {
      title: '充状态',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      sorter: (a, b) => {
        const A = a.status.deposit === 'open'
        const B = b.status.deposit === 'open'
        return A > B ? -1 : 1
      },
      render: (text, row, index) => {
        if (!row.status || Reflect.ownKeys(row.status).length === 0) {
          return '-'
        }
        return (
          <div className="opacity-50 cursor-default hover:opacity-100">
            <Tag onClick={() => {
              copy(row.token)
              message.info(`已复制 ${row.token}`)
            }} className="cursor-pointer" color={row.status.deposit === 'open' ? '#2db7f5' : '#f50'}>
              充 {row.status.deposit === 'open'
                ? '√'
                : (row.status.deposit === 'close' ? '×' : '-')}
            </Tag>
          </div>
        )
      },
    }
    table1Columns.splice(4, 0, column1)
    table2Columns.splice(4, 0, column2)
  }

  function checkNotify (updateData) {
    const A = (isWatchedCexToDex && (updateData.formulaA >= notifyValueOfFormulaA))
    const B = (isWatchedDexToCex && (updateData.formulaB >= notifyValueOfFormulaB))
    const ruleResult = !!(A || B)
    if (isEnabledNotifying && ruleResult) {
      return dayjs().unix()
    }
    return 0
  }

  useEffect(() => {
    let notify = 0
    tableDataA.forEach((item) => {
      if (notify) {
        return true
      }
      notify = checkNotify(item)
    })
    tableDataB.forEach((item) => {
      if (notify) {
        return true
      }
      notify = checkNotify(item)
    })
    if (notify > 0) {
      setLatestNotifyTimestamp(notify)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableDataA, tableDataB])


  const table1ColumnTitleArray = (() => {
    const table1ColumnTitleArray = []
    const getTableColumnArray = (columns = table1Columns) => {
      columns.forEach((item) => {
        table1ColumnTitleArray.push(item.title)
        item.children && getTableColumnArray(item.children)
      })
    }
    getTableColumnArray()
    return table1ColumnTitleArray
  })()
  const table2ColumnTitleArray = (() => {
    const table2ColumnTitleArray = []
    const getTableColumnArray = (columns = table2Columns) => {
      columns.forEach((item) => {
        table2ColumnTitleArray.push(item.title)
        item.children && getTableColumnArray(item.children)
      })
    }
    getTableColumnArray()
    return table2ColumnTitleArray
  })()
  const symbolArray = originTableData ? originTableData.map((item) => item.key) : []
  const groupSymbolObject = (() => {
    const groupSymbolObject = {}
    symbolArray && symbolArray.sort().forEach((symbol) => {
      const firstLetter = String(symbol).toUpperCase()[0]
      if (!Reflect.has(groupSymbolObject, firstLetter)) {
        groupSymbolObject[firstLetter] = []
      }
      groupSymbolObject[firstLetter].push(symbol)
    })
    return groupSymbolObject
  })()

  const [checkedTable1ColumnList, setCheckedTable1ColumnList] = useState(table1ColumnTitleArray)
  const [checkedTable2ColumnList, setCheckedTable2ColumnList] = useState(table2ColumnTitleArray)

  table1Columns.forEach((item) => {
    item.className = `${!checkedTable1ColumnList.includes(item.title) && 'hidden'}`
    item.children && (
      item.children.forEach((childItem) => {
        childItem.className = `${!checkedTable1ColumnList.includes(childItem.title) && 'hidden'}`
      })
    )
  })
  table2Columns.forEach((item) => {
    item.className = `${!checkedTable2ColumnList.includes(item.title) && 'hidden'}`
    item.children && (
      item.children.forEach((childItem) => {
        childItem.className = `${!checkedTable2ColumnList.includes(childItem.title) && 'hidden'}`
      })
    )
  })

  const CheckboxGroup = Checkbox.Group
  const monitorCount = Array.from(new Set(tableData.map((node) => node.symbol)))

  return (
    <>
      <InfoBarComponent monitorCount={monitorCount.length}>
        <div className="flex flex-row justify-between space-x-2">
          <div className="relative">
            <Switch size="small" checkedChildren="Performance" unCheckedChildren="标准模式" onChange={onChangeMode} checked={systemMode} />
          </div>
          <div className="relative">
            <div
              className="transition-all duration-200 text-white px-4 py-1 border-1 rounded-full opacity-60 cursor-pointer hover:opacity-100 flex flex-row items-center"
              onClick={() => {
                setIsShowColumnFilter(!isShowColumnFilter)
              }}>
              字段筛选 {isShowColumnFilter ? <UpOutlined/> : <DownOutlined/>}
            </div>
            {isShowColumnFilter && (
              <div className="bg-[rgba(255,255,255,.95)] p-4 absolute right-0 top-10 w-[300px] z-99">
                <div className="flex flex-row flex-nowrap justify-between space-x-2">
                  <div>
                    <div className="px-2 py-1 bg-indigo-100 mb-4">Left</div>
                    <CheckboxGroup options={table1ColumnTitleArray} value={checkedTable1ColumnList}
                      onChange={setCheckedTable1ColumnList}/>
                  </div>
                  <div>
                    <div className="px-2 py-1 bg-indigo-100 mb-4">Right</div>
                    <CheckboxGroup options={table2ColumnTitleArray} value={checkedTable2ColumnList}
                      onChange={setCheckedTable2ColumnList}/>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="relative">
            <div
              className="transition-all duration-200 text-white px-4 py-1 border-1 rounded-full opacity-50 cursor-pointer hover:opacity-100 flex flex-row items-center"
              onClick={() => {
                setIsShowSymbolFilter(!isShowSymbolFilter)
              }}>
              币种屏蔽 {blockedSymbols && blockedSymbols.length > 0 && (<span className="text-white bg-[rgba(255,255,255,.1)] ml-2">({blockedSymbols.length})</span>)} {isShowSymbolFilter ? <UpOutlined/> : <DownOutlined/>}
            </div>
            {isShowSymbolFilter && (
              <div
                className="bg-[rgba(255,255,255,.95)] p-4 absolute right-0 top-10 w-[180px] z-99 max-h-[600px] w-[800px] overflow-auto">
                <div className="border-b-1 py-4 space-x-2">
                  <div
                    className="text-sm inline-block cursor-pointer px-2 py-1 rounded-full border-1 border-indigo-200 text-indigo-400"
                    onClick={() => {
                      setBlockedSymbols(blockedSymbolsCheckAll ? [...symbolArray] : [])
                      setBlockedSymbolsCheckAll(!blockedSymbolsCheckAll)
                    }}>
                    屏蔽 / 取消屏蔽 全部
                  </div>
                  <div
                    className="text-sm inline-block cursor-pointer px-2 py-1 rounded-full border-1 border-indigo-200 text-indigo-400"
                    onClick={() => {
                      setEnableBlockedValueOfFormulaA(!enableBlockedValueOfFormulaA)
                    }}>
                    {enableBlockedValueOfFormulaA ? `取消屏蔽公式A<${blockedValueOfFormulaA}` : `屏蔽公式A<${blockedValueOfFormulaA}`}
                  </div>
                  <div
                    className="text-sm inline-block cursor-pointer px-2 py-1 rounded-full border-1 border-indigo-200 text-indigo-400"
                    onClick={() => {
                      setEnableBlockedValueOfFormulaB(!enableBlockedValueOfFormulaB)
                    }}>
                    {enableBlockedValueOfFormulaB ? `取消屏蔽公式B<${blockedValueOfFormulaB}` : `屏蔽公式B<${blockedValueOfFormulaB}`}
                  </div>
                  <div
                    className="text-sm inline-block cursor-pointer px-2 py-1 rounded-full border-1 border-indigo-200 text-indigo-400"
                    onClick={() => {
                      setEnableBlockedSymbolsWithdrawal(!enableBlockedSymbolsWithdrawal)
                    }}>
                    {enableBlockedSymbolsWithdrawal ? '取消屏蔽无法提取' : '屏蔽无法提取'}
                  </div>
                  <div
                    className="text-sm inline-block cursor-pointer px-2 py-1 rounded-full border-1 border-indigo-200 text-indigo-400"
                    onClick={() => {
                      setEnableBlockedNoBalance(!enableBlockedNoBalance)
                    }}>
                    {enableBlockedNoBalance ? '取消屏蔽没有余额' : '屏蔽没有余额'}
                  </div>
                  <div
                    className="text-sm inline-block cursor-pointer px-2 py-1 rounded-full border-1 border-indigo-200 text-indigo-400"
                    onClick={() => {
                      setEnableBlockedSymbolsDeposit(!enableBlockedSymbolsDeposit)
                    }}>
                    {enableBlockedSymbolsDeposit ? '取消屏蔽无法充值' : '屏蔽无法充值'}
                  </div>
                </div>
                {groupSymbolObject && Reflect.ownKeys(groupSymbolObject).map((firstLetter) => (
                  <div className="space-y-2 py-2 border-b-2 border-indigo-100" key={firstLetter}>
                    <div className="font-bold text-xl text-indigo-500">{firstLetter}</div>
                    {groupSymbolObject[firstLetter] && groupSymbolObject[firstLetter].map((symbol) => (
                      <Checkbox
                        key={symbol}
                        defaultChecked={blockedSymbols.find((symbolValue) => symbolValue === symbol)}
                        checked={blockedSymbols.find((symbolValue) => symbolValue === symbol)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setBlockedSymbols(Array.from(new Set([...blockedSymbols, symbol])))
                          } else {
                            setBlockedSymbols(Array.from(new Set([...blockedSymbols.filter((symbolValue) => symbolValue !== symbol)])))
                          }
                        }}>
                        {symbol}
                      </Checkbox>
                    ))}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </InfoBarComponent>

      <Row gutter={8}>
        <Col span={24}>
          <WithdrawBar ref={ref} tokens={props.tokens} />
        </Col>
        <Col span={12}>
          <Table
            columns={table1Columns}
            dataSource={tableDataA}
            size="middle"
            pagination={false}
            rowKey="key"
            scroll={{y: '70vh'}}
          />
        </Col>
        <Col span={12}>
          <Table
            columns={table2Columns}
            dataSource={tableDataB}
            size="middle"
            pagination={false}
            rowKey="key"
            scroll={{y: '70vh'}}
          />
        </Col>
      </Row>

      {isShowMonitorDetailModal && (<MonitorDetailModalComponent item={activeMonitorItem}/>)}
    </>
  )
}

export default TableComponent
