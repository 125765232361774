import React, {useImperativeHandle, useState} from 'react'
import {useRecoilState, useRecoilValue} from 'recoil'
import fly from '../plugins/flyioPlugin'
import {serverApiURI, wsURI} from '../config/apiConfig'
import {Input, Space, Button, message} from 'antd'
import {accessTokenSelector} from '../states/authState'
import {cexAtom, dexAtom, modeSelector} from '../states/exchangeState'
import {tbAddressAtom, usdtAmountSelector} from '../states/settingState'

const WithdrawBarComponent = React.forwardRef((props, ref) => {

  const [cex] = [useRecoilValue(cexAtom), useRecoilValue(dexAtom), useRecoilValue(modeSelector)]
  const tbAddress = useRecoilValue(tbAddressAtom)
  const [usdtAmount] = useRecoilState(usdtAmountSelector)
  const [symbol, setSymbol] = useState('')
  const [amount, setAmount] = useState('')
  const [loading, setLoading] = useState(false)

  const accessToken = useRecoilValue(accessTokenSelector)

  useImperativeHandle(ref, () => ({
    setSymbol: (v) => {
      setSymbol(v)
      setAmount('')
    }
  }))

  const fetchBalance = async () => {
    try {
      setLoading(true)
      const response = await fly.request(serverApiURI + '/api/balance', {
        symbol,
        cex,
        auth: accessToken
      }, {
        method: 'GET',
        parseJson: true,
      })
      if (response.code === -1 || response.error) {
        throw new Error(response.error)
      }
      message.success(`${symbol}余额: ${response.data.balance}`)
    } catch (e) {
      message.error(`查询失败败:${e.message}`)
    } finally {
      setLoading(false)
    }
  }

  const withdrawal = async (symbol, amount) => {
    const cfm = `确认提币：${symbol} => ${amount || '全部'}`
    if (confirm(cfm)) {
      setLoading(true)
      try {
        const response = await fly.request(serverApiURI + '/api/withdrawal', {
          symbol,
          cex,
          auth: accessToken,
          withdraw_amount: amount,
          withdraw_address: tbAddress
        }, {
          method: 'GET',
          parseJson: true,
        })
        if (response.code === -1 || response.error) {
          throw new Error(response.error)
        }
        message.success(`提币成功: ${response.data.amount} ${symbol}`)
      } catch (e) {
        message.error(`提币失败:${e.message}`)
      } finally {
        setLoading(false)
      }
    }
  }

  const onSymbolChange = (e) => {
    setSymbol(e.target.value)
  }

  const onAmountChange = (e) => {
    setAmount(e.target.value)
  }

  return (
    <>
      <div
        className="px-4 py-2 flex flex-row flex-nowrap justify-between items-center bg-[rgba(255,255,255,.1)] transition duration-200 hover:bg-opacity-30 <xl:flex-wrap <xl:space-y-4">
        <div className="text-white divide-x divide-indigo-600 flex flex-row justify-center">
          <Space>
            <Input value={symbol} onChange={onSymbolChange} className="withdraw-input" size="small" placeholder="symbol" />
            <Input type="number" onChange={onAmountChange} className="withdraw-input" size="small" placeholder="全部" />
            <Button type="primary" size="small" loading={loading} disabled={!symbol} onClick={fetchBalance}>查余额</Button>
            <Button type="primary" size="small" loading={loading} disabled={!symbol} onClick={() => {withdrawal(symbol, amount)}}>提现</Button>
            {cex !== 'gate' && <>
              <Button type="default" size="small" ghost onClick={() => {withdrawal('USDT', parseFloat(usdtAmount) + 35)}}>USDT</Button>
              <Button type="default" size="small" ghost onClick={() => withdrawal('ETH', '')}>ETH</Button>
            </>}
          </Space>
        </div>
        {props.children && (
          <div>
            {props.children}
          </div>
        )}
      </div>
    </>
  )

})

export default WithdrawBarComponent
