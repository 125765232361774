'use strict'

const coinConfig = [
  { symbol: 'ACH', token: '0xed04915c23f00a313a544955524eb7dbd823143d', decimals: '8', officialSite: 'http://www.alchemytech.io/ ' },
  { symbol: 'LRC', token: '0xbbbbca6a901c926f240b89eacb641d8aec7aeafd', decimals: '18', officialSite: 'https://loopring.org/ ' },
  { symbol: 'LINK', token: '0x514910771af9ca656af840dff83e8264ecf986ca', decimals: '18', officialSite: 'https://chain.link/ ' },
  { symbol: 'DFA', token: '0x62959c699a52ec647622c91e79ce73344e4099f5', decimals: '18', officialSite: 'https://www.define.one/ ' },
  { symbol: 'HT', token: '0x6f259637dcd74c767781e37bc6133cd6a68aa161', decimals: '18', officialSite: 'https://www.huobipro.com/ ' },
  { symbol: 'UNI', token: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984', decimals: '18', officialSite: 'https://uniswap.org/ ' },
  { symbol: 'MATIC', token: '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0', decimals: '18', officialSite: 'https://matic.network/ ' },
  { symbol: 'CRV', token: '0xD533a949740bb3306d119CC777fa900bA034cd52', decimals: '18', officialSite: 'https://www.curve.fi/ ' },
  { symbol: 'AXS', token: '0xbb0e17ef65f82ab018d8edd776e8dd940327b28b', decimals: '18', officialSite: 'https://axieinfinity.com/ ' },
  { symbol: 'SUSHI', token: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2', decimals: '18', officialSite: 'https://sushi.com/ ' },
  { symbol: 'AAVE', token: '0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9', decimals: '18', officialSite: 'https://aave.com/ ' },
  { symbol: 'SHIB', token: '0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce', decimals: '18', officialSite: 'https://shibatoken.com/ ' },
  { symbol: 'GRT', token: '0xc944e90c64b2c07662a292be6244bdf05cda44a7', decimals: '18', officialSite: 'https://thegraph.com/ ' },
  { symbol: 'CHZ', token: '0x3506424f91fd33084466f402d5d97f05f8e3b4af', decimals: '18', officialSite: 'https://www.chiliz.com/ ' },
  { symbol: 'SAND', token: '0x3845badAde8e6dFF049820680d1F14bD3903a5d0', decimals: '18', officialSite: 'http://www.sandbox.game/ ' },
  { symbol: 'SKL', token: '0x00c83aecc790e8a4453e5dd3b0b4b3680501a7a7', decimals: '18', officialSite: 'https://skale.network/ ' },
  { symbol: 'OXT', token: '0x4575f41308EC1483f3d399aa9a2826d74Da13Deb', decimals: '18', officialSite: 'https://orchid.com/ ' },
  { symbol: 'MASK', token: '0x69af81e73a73b40adf4f3d4223cd9b1ece623074', decimals: '18', officialSite: 'https://mask.io/ ' },
  { symbol: 'OMG', token: '0xd26114cd6EE289AccF82350c8d8487fedB8A0C07', decimals: '18', officialSite: 'https://omg.network/ ' },
  { symbol: 'COMP', token: '0xc00e94cb662c3520282e6f5717214004a7f26888', decimals: '18', officialSite: 'https://compound.finance/ ' },
  { symbol: 'BAT', token: '0x0d8775f648430679a709e98d2b0cb6250d2887ef', decimals: '18', officialSite: 'https://basicattentiontoken.org/ ' },
  { symbol: 'WXT', token: '0xa02120696c7B8fE16C09C749E4598819b2B0E915', decimals: '18', officialSite: 'https://wirexapp.com/ ' },
  { symbol: 'FOR', token: '0x1fcdce58959f536621d76f5b7ffb955baa5a672f', decimals: '18', officialSite: 'https://for.tube/ ' },
  { symbol: 'MX', token: '0x11eef04c884e24d9b7b4760e7476d06ddf797f36', decimals: '18', officialSite: 'https://www.mxc.ceo/ ' },
  { symbol: 'FTT', token: '0x50d1c9771902476076ecfc8b2a83ad6b9355a4c9', decimals: '18', officialSite: 'https://ftx.com/ ' },
  { symbol: 'RSR', token: '0x8762db106b2c2a0bccb3a80d1ed41273552616e8', decimals: '18', officialSite: 'https://reserve.org/ ' },
  { symbol: 'LINA', token: '0x3e9bc21c9b189c09df3ef1b824798658d5011937', decimals: '18', officialSite: 'https://linear.finance ' },
  { symbol: 'RNDR', token: '0x6de037ef9ad2725eb40118bb1702ebb27e4aeb24', decimals: '18', officialSite: 'https://rendertoken.com/ ' },
  { symbol: 'YFI', token: '0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e', decimals: '18', officialSite: 'https://yearn.finance/ ' },
  { symbol: 'SNX', token: '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f', decimals: '18', officialSite: 'https://www.synthetix.io/ ' },
  { symbol: 'MANA', token: '0x0f5d2fb29fb7d3cfee444a200298f468908cc942', decimals: '18', officialSite: 'https://decentraland.org/ ' },
  { symbol: '1INCH', token: '0x111111111117dc0aa78b770fa6a738034120c302', decimals: '18', officialSite: 'https://Dex.io/ ' },
  { symbol: 'CHR', token: '0x8a2279d4a90b6fe1c4b30fa660cc9f926797baa2', decimals: '6', officialSite: 'https://chromia.com/ ' },
  { symbol: 'ZKS', token: '0xe4815ae53b124e7263f08dcdbbb757d41ed658c6', decimals: '18', officialSite: 'https://zks.org/en ' },
  { symbol: 'INJ', token: '0xe28b3b32b6c345a34ff64674606124dd5aceca30', decimals: '18', officialSite: 'https://injectiveprotocol.com/ ' },
  { symbol: 'MXC', token: '0x5ca381bbfb58f0092df149bd3d243b08b9a8386e', decimals: '18', officialSite: 'https://www.mxc.org/ ' },
  // { symbol: 'RAI', token: '0xf30547ff2Df1F1CBE5C8DD758B3dd098C856e38f', decimals: '18', officialSite: 'https://rai.finance ' },
  { symbol: 'PHA', token: '0x6c5bA91642F10282b576d91922Ae6448C9d52f4E', decimals: '18', officialSite: 'https://phala.network/ ' },
  { symbol: 'IOTX', token: '0x6fb3e0a217407efff7ca062d46c26e5d60a14d69', decimals: '18', officialSite: 'https://iotex.io/ ' },
  { symbol: 'REEF', token: '0xfe3e6a25e6b192a42a44ecddcd13796471735acf', decimals: '18', officialSite: 'https://reef.finance/ ' },
  { symbol: 'BAND', token: '0xba11d00c5f74255f56a5e366f4f77f5a186d7f55', decimals: '18', officialSite: 'https://bandprotocol.com/ ' },
  { symbol: 'UMA', token: '0x04Fa0d235C4abf4BcF4787aF4CF447DE572eF828', decimals: '18', officialSite: 'https://umaproject.org/ ' },
  { symbol: 'NU', token: '0x4fe83213d56308330ec302a8bd641f1d0113a4cc', decimals: '18', officialSite: 'https://www.nucypher.com/ ' },
  { symbol: 'CLV', token: '0x80C62FE4487E1351b47Ba49809EBD60ED085bf52', decimals: '18', officialSite: 'https://clover.finance/ ' },
  { symbol: 'O3', token: '0xee9801669c6138e84bd50deb500827b776777d28', decimals: '18', officialSite: 'https://o3swap.com/ ' },
  { symbol: 'ZRX', token: '0xe41d2489571d322189246dafa5ebde1f4699f498', decimals: '18', officialSite: 'https://0x.org/ ' },
  { symbol: 'MIR', token: '0x09a3EcAFa817268f77BE1283176B946C4ff2E608', decimals: '18', officialSite: 'https://eth.mirror.finance/ ' },
  { symbol: 'OGN', token: '0x8207c1ffc5b6804f6024322ccf34f29c3541ae26', decimals: '18', officialSite: 'https://www.originprotocol.com/ ' },
  { symbol: 'AKRO', token: '0x8ab7404063ec4dbcfd4598215992dc3f8ec853d7', decimals: '18', officialSite: 'https://akropolis.io/ ' },
  { symbol: 'WOO', token: '0x4691937a7508860f876c9c0a2a617e7d9e945d4b', decimals: '18', officialSite: 'https://woo.network/ ' },
  { symbol: 'ARPA', token: '0xba50933c268f567bdc86e1ac131be072c6b0b71a', decimals: '18', officialSite: 'https://arpachain.io/ ' },
  { symbol: 'NKN', token: '0x5cf04716ba20127f1e2297addcf4b5035000c9eb', decimals: '18', officialSite: 'https://nkn.org/ ' },
  { symbol: 'CVC', token: '0x41e5560054824ea6b0732e656e3ad64e20e94e45', decimals: '8', officialSite: 'https://www.civic.com/ ' },
  { symbol: 'FRONT', token: '0xf8C3527CC04340b208C854E985240c02F7B7793f', decimals: '18', officialSite: 'https://frontier.xyz ' },
  { symbol: 'YFII', token: '0xa1d0E215a23d7030842FC67cE582a6aFa3CCaB83', decimals: '18', officialSite: 'https://dfi.money/ ' },
  { symbol: 'USDC', token: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48', decimals: '6', officialSite: 'https://www.centre.io/ ' },
  { symbol: 'WTC', token: '0xb7cb1c96db6b22b0d3d9536e0108d062bd488f74', decimals: '18', officialSite: 'http://www.waltonchain.org/ ' },
  { symbol: 'POLS', token: '0x83e6f1e41cdd28eaceb20cb649155049fac3d5aa', decimals: '18', officialSite: 'https://www.polkastarter.com/ ' },
  { symbol: 'KCASH', token: '0x32d74896f05204d1b6ae7b0a3cebd7fc0cd8f9c7', decimals: '18', officialSite: 'https://www.kcash.com/ ' },
  { symbol: 'STORJ', token: '0xb64ef51c888972c908cfacf59b47c1afbc0ab8ac', decimals: '8', officialSite: 'https://storj.io/ ' },
  { symbol: 'BAL', token: '0xba100000625a3754423978a60c9317c58a424e3D', decimals: '18', officialSite: 'https://balancer.finance/ ' },
  { symbol: 'STAKE', token: '0x0Ae055097C6d159879521C384F1D2123D1f195e6', decimals: '18', officialSite: 'https://xdaichain.com/ ' },
  { symbol: 'UTK', token: '0xdc9Ac3C20D1ed0B540dF9b1feDC10039Df13F99c', decimals: '18', officialSite: 'https://utrust.com/ ' },
  { symbol: 'FIS', token: '0xef3a930e1ffffacd2fc13434ac81bd278b0ecc8d', decimals: '18', officialSite: 'https://www.stafi.io/ ' },
  { symbol: 'RLC', token: '0x607f4c5bb672230e8672085532f7e901544a7375', decimals: '9', officialSite: 'http://iex.ec/ ' },
  { symbol: 'RAD', token: '0x31c8eacbffdd875c74b94b077895bd78cf1e64a3', decimals: '18', officialSite: 'https://radicle.xyz/ ' },
  { symbol: 'CRU', token: '0x32a7C02e79c4ea1008dD6564b35F131428673c41', decimals: '18', officialSite: 'https://crust.network/ ' },
  { symbol: 'OCN', token: '0x4092678e4E78230F46A1534C0fbc8fA39780892B', decimals: '18', officialSite: 'http://www.ocnex.net/ ' },
  { symbol: 'ANKR', token: '0x8290333cef9e6d528dd5618fb97a76f268f3edd4', decimals: '18', officialSite: 'https://www.ankr.com/ ' },
  { symbol: 'SOC', token: '0x2d0e95bd4795d7ace0da3c0ff7b706a5970eb9d3', decimals: '18', officialSite: 'https://www.allsportschain.com/ ' },
  { symbol: 'ANT', token: '0xa117000000f279d81a1d3cc75430faa017fa5a2e', decimals: '18', officialSite: 'https://aragon.org/ ' },
  { symbol: 'BADGER', token: '0x3472a5a71965499acd81997a54bba8d852c6e53d', decimals: '18', officialSite: 'https://badger.finance/ ' },
  { symbol: 'NEXO', token: '0xb62132e35a6c13ee1ee0f84dc5d40bad8d815206', decimals: '18', officialSite: 'https://nexo.io/ ' },
  { symbol: 'REN', token: '0x408e41876cccdc0f92210600ef50372656052a38', decimals: '18', officialSite: 'https://renproject.io/ ' },
  { symbol: 'POND', token: '0x57b946008913b82e4df85f501cbaed910e58d26c', decimals: '18', officialSite: 'https://www.marlin.pro/ ' },
  { symbol: 'GT', token: '0xe66747a101bff2dba3697199dcce5b743b454759', decimals: '18', officialSite: 'https://gatechain.io/ ' },
  { symbol: 'TRB', token: '0x88dF592F8eb5D7Bd38bFeF7dEb0fBc02cf3778a0', decimals: '18', officialSite: 'https://tellor.io/ ' },
  { symbol: 'OGO', token: '0xff0e5e014cf97e0615cb50f6f39da6388e2fae6e', decimals: '18', officialSite: 'https://origo.network/ ' },
  { symbol: 'SMT', token: '0x55f93985431fc9304077687a35a1ba103dc1e081', decimals: '18', officialSite: 'https://smartmesh.io/ ' },
  { symbol: 'MKR', token: '0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2', decimals: '18', officialSite: 'https://makerdao.com/ ' },
  { symbol: 'API3', token: '0x0b38210ea11411557c13457D4dA7dC6ea731B88a', decimals: '18', officialSite: 'https://api3.org/ ' },
  { symbol: 'CRO', token: '0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b', decimals: '8', officialSite: 'https://chain.crypto.com/ ' },
  { symbol: 'EGT', token: '0x8e1b448ec7adfc7fa35fc2e885678bd323176e34', decimals: '18', officialSite: 'https://egretia.io/ ' },
  { symbol: 'FORTH', token: '0x77fba179c79de5b7653f68b5039af940ada60ce0', decimals: '18', officialSite: 'https://www.ampleforth.org/ ' },
  { symbol: 'ELA', token: '0xe6fd75ff38adca4b97fbcd938c86b98772431867', decimals: '18', officialSite: 'https://www.elastos.org/ ' },
  { symbol: 'MTA', token: '0xa3BeD4E1c75D00fa6f4E5E6922DB7261B5E9AcD2', decimals: '18', officialSite: 'https://mstable.org/ ' },
  { symbol: 'DF', token: '0x431ad2ff6a9c365805ebad47ee021148d6f7dbe0', decimals: '18', officialSite: 'https://dforce.network/ ' },
  { symbol: 'DAI', token: '0x6b175474e89094c44da98b954eedeac495271d0f', decimals: '18', officialSite: 'https://makerdao.com/ ' },
  { symbol: 'BORING', token: '0xbc19712feb3a26080ebf6f2f7849b417fdd792ca', decimals: '18', officialSite: 'https://www.boringdao.com/ ' },
  { symbol: 'TITAN', token: '0x3A8cCCB969a61532d1E6005e2CE12C200caeCe87', decimals: '18', officialSite: 'https://titanswap.org/ ' },
  { symbol: 'RING', token: '0x9469d013805bffb7d3debe5e7839237e535ec483', decimals: '18', officialSite: 'https://darwinia.network/ ' },
  { symbol: 'BLZ', token: '0x5732046a883704404f284ce41ffadd5b007fd668', decimals: '18', officialSite: 'https://bluzelle.com/ ' },
  { symbol: 'KAN', token: '0x1410434b0346f5be678d0fb554e5c7ab620f8f4a', decimals: '18', officialSite: 'http://kan.land/ ' },
  { symbol: 'STN', token: '0xe63d6b308bce0f6193aec6b7e6eba005f41e36ab', decimals: '18', officialSite: 'https://www.stonedefi.io/ ' },
  { symbol: 'HPT', token: '0xa66daa57432024023db65477ba87d4e7f5f95213', decimals: '18', officialSite: 'https://www.huobipool.com/ ' },
  { symbol: 'ABT', token: '0xb98d4c97425d9908e66e53a6fdf673acca0be986', decimals: '18', officialSite: 'https://www.arcblock.io/ ' },
  { symbol: 'ELF', token: '0xbf2179859fc6D5BEE9Bf9158632Dc51678a4100e', decimals: '18', officialSite: 'http://aelf.io/ ' },
  { symbol: 'CNNS', token: '0x6c3be406174349cfa4501654313d97e6a31072e1', decimals: '18', officialSite: 'https://cnns.io/ ' },
  { symbol: 'INSUR', token: '0x544c42fbb96b39b21df61cf322b5edc285ee7429', decimals: '18', officialSite: 'https://www.insurace.io/ ' },
  { symbol: 'SKM', token: '0x048fe49be32adfc9ed68c37d32b5ec9df17b3603', decimals: '18', officialSite: 'https://skrumble.network/ ' },
  { symbol: 'HOT', token: '0x9af839687f6c94542ac5ece2e317daae355493a1', decimals: '18', officialSite: 'https://hydroprotocol.io/ ' },
  { symbol: 'UUU', token: '0x3543638ed4a9006e4840b105944271bcea15605d', decimals: '18', officialSite: 'https://u.network/ ' },
  { symbol: 'SEELE', token: '0xb1e93236ab6073fdac58ada5564897177d4bcc43', decimals: '18', officialSite: 'https://seelen.pro/index_en.html ' },
  { symbol: 'DAC', token: '0xaad54c9f27b876d2538455dda69207279ff673a5', decimals: '18', officialSite: 'https://www.davinci.vision/ ' },
  { symbol: 'CVP', token: '0x38e4adb44ef08f22f5b5b76a8f0c2d0dcbe7dca1', decimals: '18', officialSite: 'https://powerpool.finance/ ' },
  { symbol: 'FTI', token: '0x943ed852dadb5c3938ecdc6883718df8142de4c8', decimals: '18', officialSite: 'https://www.fanstime.org/ ' },
  { symbol: 'GOF', token: '0x488E0369f9BC5C40C002eA7c1fe4fd01A198801c', decimals: '18', officialSite: 'https://golff.finance/ ' },
  { symbol: 'XRT', token: '0x7de91b204c1c737bcee6f000aaa6569cf7061cb7', decimals: '9', officialSite: 'https://robonomics.network/en/ ' },
  { symbol: 'SWRV', token: '0xb8baa0e4287890a5f79863ab62b7f175cecbd433', decimals: '18', officialSite: 'https://swerve.fi/ ' },
  { symbol: 'CTSI', token: '0x491604c0fdf08347dd1fa4ee062a822a5dd06b5d', decimals: '18', officialSite: 'https://cartesi.io/ ' },
  { symbol: 'AUCTION', token: '0xa9b1eb5908cfc3cdf91f9b8b3a74108598009096', decimals: '18', officialSite: 'https://bounce.finance/ ' },
  { symbol: 'MDS', token: '0x66186008C1050627F979d464eABb258860563dbE', decimals: '18', officialSite: 'http://www.medishares.org/ ' },
  { symbol: 'MLN', token: '0xec67005c4e498ec7f55e092bd1d35cbc47c91892', decimals: '18', officialSite: 'https://enzyme.finance/ ' },
  { symbol: 'APN', token: '0xd4342a57ecf2fe7ffa37c33cb8f63b1500e575e6', decimals: '18', officialSite: 'null' },
  { symbol: 'VALUE', token: '0x49e833337ece7afe375e44f4e3e8481029218e5c', decimals: '18', officialSite: 'https://valuedefi.io/ ' },
  // { symbol: 'HIT', token: '0x7995ab36bB307Afa6A683C24a25d90Dc1Ea83566', decimals: '6', officialSite: 'http://hitchain.org/index.html ' },
  { symbol: 'SNT', token: '0x744d70fdbe2ba4cf95131626614a1763df805b9e', decimals: '18', officialSite: 'https://status.im/ ' },
  { symbol: 'SWFTC', token: '0x0bb217E40F8a5Cb79Adf04E1aAb60E5abd0dfC1e', decimals: '8', officialSite: 'http://www.swft.pro/ ' },
  { symbol: 'TNB', token: '0xf7920b0768ecb20a123fac32311d07d193381d6f', decimals: '18', officialSite: 'http://tnb.fund/ ' },
  { symbol: 'LET', token: '0xfa3118b34522580c35ae27f6cf52da1dbb756288', decimals: '6', officialSite: 'https://www.linkeye.com/#/ ' },
  { symbol: 'STPT', token: '0xde7d85157d9714eadf595045cc12ca4a5f3e2adb', decimals: '18', officialSite: 'https://stp.network/ ' },
  { symbol: 'EKT', token: '0x4ecdb6385f3db3847f9c4a9bf3f9917bb27a5452', decimals: '8', officialSite: 'http://ekt8.io/ ' },
  { symbol: 'DHT', token: '0xca1207647ff814039530d7d35df0e1dd2e91fa84', decimals: '18', officialSite: 'https://www.dhedge.org/ ' },
  // { symbol: 'EM', token: '0x35b08722aa26be119c1608029ccbc976ac5c1082', decimals: '8', officialSite: 'https://eminer.pro/#/ ' },
  { symbol: 'BNT', token: '0x1f573d6fb3f13d689ff844b4ce37794d79a7ff1c', decimals: '18', officialSite: 'https://bancor.network/ ' },
  { symbol: 'RUFF', token: '0xf278c1ca969095ffddded020290cf8b5c424ace2', decimals: '18', officialSite: 'http://ruffchain.com/ ' },
  { symbol: 'VIDY', token: '0xc77b230f31b517f1ef362e59c173c2be6540b5e8', decimals: '18', officialSite: 'http://www.vidy.com/ ' },
  { symbol: 'CRE', token: '0x115eC79F1de567eC68B7AE7eDA501b406626478e', decimals: '18', officialSite: 'https://carryprotocol.io/ ' },
  { symbol: 'WNXM', token: '0x0d438f3b5175bebc262bf23753c1e53d03432bde', decimals: '18', officialSite: 'https://nexusmutual.io/ ' },
  { symbol: 'DTA', token: '0x69b148395ce0015c13e36bffbad63f49ef874e03', decimals: '18', officialSite: 'https://data.eco/ ' },
  { symbol: 'TOP', token: '0xdcD85914b8aE28c1E62f1C488E1D968D5aaFfE2b', decimals: '18', officialSite: 'https://www.topnetwork.org/ ' },
  { symbol: 'ITC', token: '0x5e6b6d9abad9093fdc861ea1600eba1b355cd940', decimals: '18', officialSite: 'https://iotchain.io/ ' },
  { symbol: 'BIX', token: '0xb3104b4b9da82025e8b9f8fb28b3553ce2f67069', decimals: '18', officialSite: 'https://www.bibox.com/ ' },
  { symbol: 'NHBTC', token: '0x1f832091faf289ed4f50fe7418cfbd2611225d46', decimals: '18', officialSite: 'null' },
  { symbol: 'NSURE', token: '0x20945ca1df56d237fd40036d47e866c7dccd2114', decimals: '18', officialSite: 'https://nsure.network/ ' },
  { symbol: 'UIP', token: '0x4290563c2d7c255b5eec87f2d3bd10389f991d68', decimals: '18', officialSite: 'http://www.unlimitedip.io/ ' },
  { symbol: 'PVT', token: '0x7869c4a1a3f6f8684fbcc422a21ad7abe3167834', decimals: '18', officialSite: 'https://www.pivot.one/ ' },
  { symbol: 'XMX', token: '0x0f8c45b896784a1e408526b9300519ef8660209c', decimals: '8', officialSite: 'https://www.xmx.com/ ' },
  { symbol: 'YEE', token: '0x922105fad8153f516bcfb829f56dc097a0e1d705', decimals: '18', officialSite: 'https://www.yeefoundation.com/ ' },
  { symbol: 'DKA', token: '0x5dc60C4D5e75D22588FA17fFEB90A63E535efCE0', decimals: '18', officialSite: 'https://dkargo.io/ ' },
  { symbol: 'LOOM', token: '0x42476F744292107e34519F9c357927074Ea3F75D', decimals: '18', officialSite: 'https://loomx.io/ ' },
  { symbol: 'GNX', token: '0x6ec8a24cabdc339a06a172f8223ea557055adaa5', decimals: '9', officialSite: 'https://genaro.network/en ' },
  { symbol: 'LXT', token: '0xbc46d9961a3932f7d6b64abfdec80c1816c4b835', decimals: '18', officialSite: 'http://litex.io/ ' },
  { symbol: 'AST', token: '0x27054b13b1b798b345b591a4d22e6562d47ea75a', decimals: '4', officialSite: 'https://www.airswap.io/ ' },
  { symbol: 'NODE', token: '0x0c3ef32f802967db75b9d49fe1e76620151ccb81', decimals: '5', officialSite: 'https://www.wn.work/ ' },
  { symbol: 'AAC', token: '0xe75ad3aab14e4b0df8c5da4286608dabb21bd864', decimals: '5', officialSite: 'http://www.acuteangle.com/ ' },
  { symbol: 'PAX', token: '0x8e870d67f660d95d5be530380d0ec0bd388289e1', decimals: '18', officialSite: 'https://www.paxos.com/usdp/ ' },
  { symbol: 'LBA', token: '0xfe5f141bf94fe84bc28ded0ab966c16b17490657', decimals: '18', officialSite: 'https://www.mycred.io/ ' },
  { symbol: 'GLM', token: '0x7dd9c5cba05e151c895fde1cf355c9a1d5da6429', decimals: '18', officialSite: 'https://golem.network/ ' },
]

export default coinConfig
export const usdtToken = '0xdac17f958d2ee523a2206206994597c13d831ec7'
export const usdtDecimals = 6
