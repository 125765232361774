import db from '../plugins/dexiePlugin'
import dayjs from '../plugins/dayjsPlugin'
import defaultSettings from '../config/settingConfig'

export const addLog = (event, text, originData) => {
  db.logs.add({event, text, originData, datetime: dayjs().format('YYYY-MM-DD HH:mm:ss')})
}

// eslint-disable-next-line
export const addNotify = (cex, dex, direction, symbol, notifyValue, text, originData) => {
  db.notifies.add({cex, dex, direction, symbol, notifyValue, text, originData, datetime: dayjs().unix()})
}

export const updateSettingValue = (key, value) => {
  db.settings.update(key, {value})
  // addLog('配置设定', `${key} 设定为 ${value}`)
}

export const queryInitialSettingValue = async (key) => {
  const setting = await db.settings.where('key').equalsIgnoreCase(key)
    .first()
  if (setting && setting.value) {
    return setting.value
  }
  const defaultValue = defaultSettings[key]
  db.settings.put({key, value: defaultValue}, key)
  return defaultValue || ''
}
