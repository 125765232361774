import React from 'react'
import {useRecoilState} from 'recoil'
import {accessTokenSelector, expireTimestampSelector, passwordAtom, usernameAtom} from '../states/authState'
import FullScreenContentComponent from './FullScreenContent'
import fly from '../plugins/flyioPlugin'
import {serverApiURI} from '../config/apiConfig'
import {message} from 'antd'

const UserLoginComponent = () => {

  const [username, setUsername] = useRecoilState(usernameAtom)
  const [password, setPassword] = useRecoilState(passwordAtom)
  const [accessToken, setAccessToken] = useRecoilState(accessTokenSelector)
  const [expireTimestamp, setAccessTokenExpireTimestamp] = useRecoilState(expireTimestampSelector)

  const requestAuth = async () => {
    if (!username || !password) {
      message.warn('请填写用户名和密码')
      return
    }
    try {
      const {code, data: {token, expires}} = await fly.request(serverApiURI + '/api/auth', {
        username,
        password,
      }, {
        method: 'POST',
        parseJson: true,
      })
      if (code !== 0 || !token) {
        message.error('登录异常')
        return
      }
      setAccessToken(token)
      setAccessTokenExpireTimestamp(Number((new Date().getTime() / 1000) + expires))
      message.success('登录成功')
    } catch (e) {
      message.error('授权失败，请检查用户名和密码')
    }
  }

  return (
    <>
      <FullScreenContentComponent>
        <div
          className="bg-[rgba(255,255,255,.2)] p-8 text-[1.2rem] space-y-4 hover:ring-3 hover:shadow-md">
          <div className="space-y-4">
            <div className="text-[rgba(255,255,255,.5)]">Username</div>
            <input
              type="text"
              className="rounded bg-[rgba(255,255,255,.1)] w-full h-10 px-2 text-white focus:bg-white focus:text-[#666] transition-all duration-200"
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="space-y-4">
            <div className="text-[rgba(255,255,255,.5)]">Password</div>
            <input
              type="password"
              className="rounded bg-[rgba(255,255,255,.1)] w-full h-10 px-2 text-white focus:bg-white focus:text-[#666] transition-all duration-200"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>
        <div
          onClick={() => {requestAuth()}}
          className="font-600 text-[1.2rem] rounded bg-[rgba(255,255,255,.1)] text-center w-full leading-[3rem] cursor-pointer px-2 text-white focus:bg-white focus:text-[#666] transition-all duration-200 transition-all duration-200 hover:bg-white hover:text-[#666]">
          LOGIN
        </div>
      </FullScreenContentComponent>
    </>
  )
}

export default UserLoginComponent
