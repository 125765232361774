import React, { useState } from 'react'
import {Button, message, Modal, Input} from 'antd'
import {useRecoilState, useRecoilValue} from 'recoil'
import {isShowMonitorDetailModalAtom} from '../states/appState'
import fly from '../plugins/flyioPlugin'
import {serverApiURI} from '../config/apiConfig'
import {addLog} from '../services/dbService'
import {cexAtom, dexAtom} from '../states/exchangeState'
import {accessTokenSelector, authUserSelector} from '../states/authState'

const MonitorDetailModalComponent = ({item}) => {
  const [isShowMonitorDetailModal, setIsShowMonitorDetailModal] = useRecoilState(isShowMonitorDetailModalAtom)
  const [cex, dex] = [useRecoilValue(cexAtom), useRecoilValue(dexAtom)]
  const [tag, setTag] = useState(item.tag)
  const accessToken = useRecoilValue(accessTokenSelector)
  const authUser = useRecoilValue(authUserSelector)

  const requestRemoveMonitorItem = async () => {
    try {
      const responseOfAPI = await fly.request(serverApiURI + '/api/coin/remove', {
        symbol: item.symbol,
        cex,
        dex,
        accessToken
      }, {
        method: 'GET',
        parseJson: true,
      })
      addLog('移除监控', `移除 ${item.symbol} 成功`, {removeItem: item, responseOfAPI})
      message.error('添加成功！重新启动服务后生效。')
    } catch (e) {
      addLog('移除监控', `移除 ${item.symbol} 失败`, {removeItem: item, error: e})
    }
    setIsShowMonitorDetailModal(false)
  }

  const handleCancel = () => {
    setIsShowMonitorDetailModal(false)
  }

  const onTagChange = (e) => {
    setTag(e.target.value)
  }

  const saveTag = async () => {
    try {
      await fly.request(serverApiURI + '/api/coin/tag', {
        symbol: item.symbol,
        cex,
        dex,
        tag
      }, {
        method: 'GET',
        parseJson: true,
      })
      message.success('修改成功！重新启动服务后生效。')
    } catch (e) {
      message.error('修改失败')
    }
  }

  return (
    <>
      <Modal
        title={`查看 ${item.symbol} 详情`}
        visible={isShowMonitorDetailModal}
        onCancel={handleCancel}
        footer={authUser.type  === 'master' && (
          <Button type="primary" danger onClick={requestRemoveMonitorItem}>移除监控项</Button>
        )}
      >
        <div className="space-y-2 max-h-[50vh] overflow-auto">
          {authUser.type  === 'master' && <div key="tag-input" className="rounded bg-[rgba(255,255,255,.1)] p-2 hover:ring-2 space-y-2">
            <div className="text-[1.1rem]">Tag</div>
            <div>
              <Input value={tag} onChange={onTagChange} suffix={<Button onClick={saveTag}>保存</Button>} />
            </div>
          </div>}
          {Object.entries(item).map((entry) => (
            <div key={entry[0]} className="rounded bg-[rgba(255,255,255,.1)] p-2 hover:ring-2 space-y-2">
              <div className="text-[1.1rem]">{entry[0]}</div>
              <div>{JSON.stringify(entry[1])}</div>
            </div>
          ))}
        </div>
      </Modal>
    </>
  )

}

export default MonitorDetailModalComponent
