/* eslint-disable no-console */
import {useSocket} from '../plugins/socketioPlugin'
import {useInterval, useUnmount, useUpdateEffect} from 'ahooks'
import fly from '../plugins/flyioPlugin'
import dayjs from '../plugins/dayjsPlugin'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {
  blockedSymbolsSelector,
  blockedValueOfFormulaASelector,
  blockedValueOfFormulaBSelector,
  cexBalanceAmountSelector,
  enableBlockedSymbolDepositSelector,
  enableBlockedSymbolWithdrawalSelector,
  enableBlockedValueOfFormulaASelector,
  enableBlockedValueOfFormulaBSelector,
  isEnabledNotifyingSelector,
  isEnabledRefreshingAtom,
  isWatchedCexToDexSelector,
  isWatchedDexToCexSelector,
  notifyValueOfFormulaASelector,
  notifyValueOfFormulaBSelector,
  usdtAmountSelector
} from '../states/settingState'
import {ethAtom, gasAtom, latestNotifyTimestampAtom, systemModeAtom} from '../states/appState'
import {addLog} from '../services/dbService'
import {useEffect, useState} from 'react'
import {serverApiURI} from '../config/apiConfig'
import {cexAtom, dexAtom, modeSelector} from '../states/exchangeState'
import {accessTokenSelector, authUserSelector} from '../states/authState'

const CachedTokens = {}

const useMonitor = () => {
  const [cex, dex, mode] = [useRecoilValue(cexAtom), useRecoilValue(dexAtom), useRecoilValue(modeSelector)]
  const cexBalanceAmount = useRecoilValue(cexBalanceAmountSelector)
  const systemMode = useRecoilValue(systemModeAtom)
  const usdtAmount = useRecoilValue(usdtAmountSelector)
  const [tokens, setTokens] = useState([])
  const isEnabledRefreshing = useRecoilValue(isEnabledRefreshingAtom)
  const isEnabledNotifying = useRecoilValue(isEnabledNotifyingSelector)
  const isWatchedDexToCex = useRecoilValue(isWatchedDexToCexSelector)
  const isWatchedCexToDex = useRecoilValue(isWatchedCexToDexSelector)
  const notifyValueOfFormulaA = useRecoilValue(notifyValueOfFormulaASelector)
  const notifyValueOfFormulaB = useRecoilValue(notifyValueOfFormulaBSelector)
  const setGas = useSetRecoilState(gasAtom)
  const setEth = useSetRecoilState(ethAtom)
  const accessToken = useRecoilValue(accessTokenSelector)
  const authUser = useRecoilValue(authUserSelector)
  const blockedSymbols = useRecoilValue(blockedSymbolsSelector)
  const enableBlockedValueOfFormulaA = useRecoilValue(enableBlockedValueOfFormulaASelector)
  const enableBlockedValueOfFormulaB = useRecoilValue(enableBlockedValueOfFormulaBSelector)
  const enableBlockedSymbolsWithdrawal = useRecoilValue(enableBlockedSymbolWithdrawalSelector)
  const enableBlockedSymbolsDeposit = useRecoilValue(enableBlockedSymbolDepositSelector)
  const [blockedValueOfFormulaA, blockedValueOfFormulaB] = [useRecoilValue(blockedValueOfFormulaASelector), useRecoilValue(blockedValueOfFormulaBSelector)]
  const socket = useSocket()

  const startListeners = () => {
    const io = socket.connect()
    io.on('connect', () => {
      addLog('Socket', 'Socket已连接')
    })
    io.on('gas', (gas) => {
      setGas(gas)
    })
    io.on('ethPrice', (ethPrice) => {
      setEth(ethPrice)
    })
    io.on('quotes', (item) => {
      if (item.data.missing) {
        console.log(item.symbol, 'missing')
      }
      if (item.cex === cex && item.dex === dex) {
        handleMonitorDataChange(item)
      }
    })
    // io.on('price', (item) => {
    //   const {symbol, cex, dex} = item
    //   if (!CachedTokens[symbol]) {
    //     return false
    //   }
    //   if (cex) {
    //     if (cex.buy) {
    //       CachedTokens[symbol].buyOnePriceOfCex = cex.buy
    //     }
    //     if (cex.sell) {
    //       CachedTokens[symbol].sellOnePriceOfCex = cex.sell
    //     }
    //   }
    //   if (dex) {
    //     if (dex.buy) {
    //       CachedTokens[symbol].buyOnePriceOfDex = dex.buy
    //     }
    //     if (dex.sell) {
    //       CachedTokens[symbol].sellOnePriceOfCex = dex.sell
    //     }
    //   }
    // })
    io.on('disconnect', () => {
      addLog('Socket', 'Socket连接断开')
      // setIsEnabledRefreshing(false)
      socket.disconnect()
    })
    io.on('error', (err) => {
      addLog('Socket', 'Socket连接异常', {err})
      // setIsEnabledRefreshing(false)
    })
  }

  // const latestNotifyTimestamp = {directionA: {}, directionB: {}}
  const setLatestNotifyTimestamp = useSetRecoilState(latestNotifyTimestampAtom)
  const handleMonitorDataChange = (item) => {
    const isBlockedSymbol = blockedSymbols.includes(item.symbol)
    if (isBlockedSymbol) {
      return
    }
    const updateData = generateUpdateItem(item)
    const key = `${item.symbol}-${item.direction}`
    CachedTokens[key] = updateData

    // const notifyRules = [
    //   (
    //     (isWatchedCexToDex && (updateData.formulaA >= notifyValueOfFormulaA))        ||
    //     (isWatchedDexToCex && (updateData.formulaB >= notifyValueOfFormulaB))
    //   )
    // ]
    // if (enableBlockedValueOfFormulaA || enableBlockedValueOfFormulaB) {
    //   notifyRules.push(updateData.formulaA >= blockedValueOfFormulaA || updateData.formulaB >= blockedValueOfFormulaB)
    // }
    // if (enableBlockedSymbolsWithdrawal) {
    //   if (enableBlockedSymbolsDeposit && updateData.status.deposit === 'close' && updateData.status.withdrawal === 'open') {
    //     notifyRules.push(false)
    //   } else {
    //     notifyRules.push(updateData.status.withdrawal === 'open')
    //   }
    // }
    // if (enableBlockedSymbolsDeposit) {
    //   if (enableBlockedSymbolsWithdrawal && updateData.status.withdrawal === 'close' && updateData.status.deposit === 'open') {
    //     notifyRules.push(false)
    //   } else {
    //     notifyRules.push(updateData.status.deposit === 'open')
    //   }
    // }
    // const ruleResult = notifyRules.reduce((current, next) => current && next)
    // if (isEnabledNotifying && ruleResult) {
    //   // setLatestNotifyTimestamp(dayjs().unix())
    // }

    // Old business code (CallNotifyBox && insertNotifyLogToDB)
    // if (isEnabledNotifying) {
    // const currentUnixTime = dayjs().unix()
    // updateData['latestNotifyUnixTime'] = currentUnixTime
    // if (
    //   isWatchedCexToDex &&
    //   (updateData.formulaA >= notifyValueOfFormulaA) &&
    //   (!Reflect.has(latestNotifyTimestamp.directionA, item.symbol) || currentUnixTime - latestNotifyTimestamp.directionA[item.symbol] > 5)
    // ) {
    //   const notifyText = `${item.symbol} 公式A警报值 ${updateData.formulaA}，时间为${dayjs().format('YYYY-MM-DD HH:mm:ss')}`
    //   addNotify(cex, dex, `${cex} > ${dex}`, item.symbol, updateData.formulaB, notifyText, {
    //     serverResponse: item,
    //     updateData
    //   })
    //   latestNotifyTimestamp.directionA[item.symbol] = currentUnixTime
    // }
    // if (
    //   isWatchedDexToCex &&
    //   (updateData.formulaB >= notifyValueOfFormulaB) &&
    //   (!Reflect.has(latestNotifyTimestamp.directionB, item.symbol) || currentUnixTime - latestNotifyTimestamp.directionB[item.symbol] > 5)
    // ) {
    //   const notifyText = `${item.symbol} 公式B警报值 ${updateData.formulaB}，时间为${dayjs().format('YYYY-MM-DD HH:mm:ss')}`
    //   addNotify(cex, dex, `${dex} > ${cex}`, item.symbol, updateData.formulaB, notifyText, {
    //     serverResponse: item,
    //     updateData
    //   })
    //   latestNotifyTimestamp.directionB[item.symbol] = currentUnixTime
    // }
    // }
  }

  const generateUpdateItem = (item) => {
    let itemObject = {
      key: `${item.symbol}-${item.direction}`,
      cex,
      dex,
      symbol: item.symbol,
      direction: item.direction,
      tag: item.tag,
      pair: item.pair,
      token: item.contract,
      cexBuyAmount: item.cexBuyAmount,
      usdtAmount: Reflect.has(item, 'amount') && item.amount,
      updateTime: dayjs(item.timestamp).format('YYYY-MM-DD HH:mm:ss'),
    }
    const check = cexBalanceAmount ? parseFloat(cexBalanceAmount) : parseFloat(itemObject.usdtAmount)

    if (Reflect.has(item, 'data')) {
      const cexBalance = item.data.cexBalance > -1 ? parseFloat(item.data.cexBalance) : -1
      const cexBalanceAmount = cexBalance > -1 && item.data.decimals ? cexBalance / (10 ** item.data.decimals) : cexBalance
      const cexBalanceValue = cexBalanceAmount > -1 ? cexBalanceAmount * item.data.cex.sell : -1
      const balanceState = cexBalanceValue > -1
      const balanceSufficient = cexBalanceValue >= check
      itemObject.balanceState = balanceState
      itemObject.balanceSufficient = balanceSufficient

      itemObject = {
        ...itemObject,
        buyOnePriceOfCex: item.data.cex.buy,
        sellPriceOfDex: item.data.dex,
        sellOnePriceOfCex: item.data.cex.sell,
        buyOnePriceOfDex: item.data.dex,
        formulaA: item.direction === 'sell' ? (item.data.cex.sell ? (item.data.dex / item.data.cex.sell) : 0) : 0,
        formulaB: item.direction === 'buy' ? (item.data.dex ? (item.data.cex.buy / item.data.dex) : 0) : 0,
        status: item.data.status || {},
        cexBalanceAmount,
        cexBalanceValue
      }
    }
    return itemObject
  }

  // const initCoinsData = async () => {
  //   const cancelLoading = message.loading('加载服务端 coinList 数据中...', 0)
  //
  //   const coins = await requestAllCoins() || []
  //
  //   if (coins.length === 0) {
  //     setIsEnabledRefreshing(false)
  //     message.error('获取服务端 coinList 异常，稍后重新启动服务再试')
  //     cancelLoading()
  //     return
  //   }
  //
  //   const monitorData = coins.map((item) => ({
  //     ...generateUpdateItem(item),
  //     updateTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  //     latestNotifyUnixTime: 0,
  //     status: {}
  //   }))
  //   if (monitorData && monitorData.length > 0) {
  //     // setTokens(monitorData)
  //   }
  //
  //   cancelLoading()
  // }

  // const requestAllCoins = async () => {
  //   const {data} = await fly.request(serverApiURI + '/api/coinlist', {
  //     cex,
  //     dex,
  //     amount: usdtAmount,
  //     accessToken
  //   }, {
  //     method: 'GET',
  //     parseJson: true,
  //   })
  //   return data
  // }

  const requestInitialEthAndGasData = async () => {
    const [ethResponse, gasResponse] = await Promise.all([
      await fly.request(serverApiURI + '/api/ethPrice', {
        accessToken
      }, {
        method: 'GET',
        parseJson: true,
      }),
      await fly.request(serverApiURI + '/api/gas', {
        accessToken
      }, {
        method: 'GET',
        parseJson: true,
      })
    ])
    setEth(ethResponse.data)
    setGas(gasResponse.data)
  }

  useUpdateEffect(() => {
    if (isEnabledRefreshing) {
      requestInitialEthAndGasData()
      isEnabledRefreshing && startListeners()
    } else {
      socket.disconnect()
    }
  }, [isEnabledRefreshing])

  useUpdateEffect(() => {
    socket.disconnect()
    isEnabledRefreshing && startListeners()
  }, [blockedSymbols, blockedValueOfFormulaA, blockedValueOfFormulaB, enableBlockedSymbolsWithdrawal, enableBlockedSymbolsDeposit, enableBlockedValueOfFormulaA, enableBlockedValueOfFormulaB])

  // useUpdateEffect(() => {
  //   (isEnabledRefreshing && authUser.type === 'master') && socket.emit('quote', {
  //     cex,
  //     dex,
  //     amount: usdtAmount,
  //     accessToken,
  //     stream: true,
  //   }, (res) => {
  //     addLog('Socket', `usdtAmount 设置为 ${usdtAmount}`, res)
  //   })
  // }, [usdtAmount])

  const [intervalOfQuoting, setIntervalOfQuoting] = useState(5000)
  const [intervalOfRefresh, setintervalOfRefresh] = useState(600)
  useEffect(() => {
    setintervalOfRefresh(systemMode ? 300 : 600)
  }, [systemMode])
  useInterval(() => {
    (isEnabledRefreshing && authUser && authUser.type === 'master' && mode !== 'ws' && socket.io) && socket.io.emit('quote', {
      cex,
      dex,
      amount: usdtAmount,
      accessToken,
      stream: true,
    }, (res) => {
      addLog('Socket', '请求最新 quotes', res)
    })
  }, intervalOfQuoting)

  useInterval(() => {
    if (isEnabledRefreshing) {
      setTokens(Object.values(CachedTokens))
    }
  }, intervalOfRefresh)

  useUnmount(() => {
    setIntervalOfQuoting(0)
    socket.disconnect()
  })

  return {
    isEnabledRefreshing,
    tokens
  }

}

export default useMonitor
