import React from 'react'
import {useRecoilValue} from 'recoil'
import {exchangeConfigAtom} from '../states/exchangeState'
import FullScreenContentComponent from './FullScreenContent'

const ChooseExchangeComponent = () => {
  const exchanges = useRecoilValue(exchangeConfigAtom)

  return (
    <>
      <FullScreenContentComponent>
        {exchanges.map((item) => (
          <div
            onClick={() => {window.open(item.url)}}
            className="bg-[rgba(255,255,255,.2)] p-8 font-600 text-[1.4rem] space-y-4 cursor-pointer hover:ring-3 hover:shadow-md" key={`${item.cex}&${item.dex}`}>
            <div>
              <span className="text-[rgba(255,255,255,.2)]">CEX</span>
              <span className="float-right ml-2 text-[rgba(255,255,255,.8)]">{String(item.cex).toUpperCase()}</span>
            </div>
            <div>
              <span className="text-[rgba(255,255,255,.2)]">DEX</span>
              <span className="float-right ml-2 text-[rgba(255,255,255,.8)]">{String(item.dex).toUpperCase()}</span>
            </div>
          </div>
        ))}
      </FullScreenContentComponent>
    </>
  )
}

export default ChooseExchangeComponent
