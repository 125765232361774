import React from 'react'

const TextLogoComponent = (props) => (
  <>
    <div
      className="cursor-default flex flex-row flex-nowrap items-center justify-between pt-3 space-x-2 inline-block space-x-4">
      <div className="py-2 space-x-2 tracking-wide text-[1.2rem] font-600" style={{borderTop: '8px solid rgba(255,255,255,.6)'}}>
        <span className="text-[rgba(255,255,255,.9)]">COIN</span>
        <span className="text-[rgba(255,255,255,.6)]">MONITOR</span>
      </div>
      {props.children}
    </div>
  </>
)

export default TextLogoComponent
