import {atom, selector} from 'recoil'
import {queryInitialSettingValue, updateSettingValue} from '../services/dbService'

export const isWsRunningAtom = atom({
  key: 'isWsRunningAtom',
  default: false,
  // default: (async () => queryInitialSettingValue('isEnabledRefreshing'))(),
})

export const isEnabledRefreshingAtom = atom({
  key: 'isEnabledRefreshing',
  default: false,
  // default: (async () => queryInitialSettingValue('isEnabledRefreshing'))(),
})

export const notifyValueOfFormulaAAtom = atom({
  key: 'notifyValueOfFormulaA',
  default: (async () => queryInitialSettingValue('notifyValueOfFormulaA'))(),
})

export const notifyValueOfFormulaASelector = selector({
  key: 'notifyValueOfFormulaA/selector',
  get: ({get}) => get(notifyValueOfFormulaAAtom),
  set: ({set}, newValue) => {
    set(notifyValueOfFormulaAAtom, newValue)
    updateSettingValue('notifyValueOfFormulaA', newValue)
  }
})

const notifyValueOfFormulaBAtom = atom({
  key: 'notifyValueOfFormulaB',
  default: (async () => queryInitialSettingValue('notifyValueOfFormulaB'))(),
})

export const notifyValueOfFormulaBSelector = selector({
  key: 'notifyValueOfFormulaB/selector',
  get: ({get}) => get(notifyValueOfFormulaBAtom),
  set: ({set}, newValue) => {
    set(notifyValueOfFormulaBAtom, newValue)
    updateSettingValue('notifyValueOfFormulaB', newValue)
  }
})

const usdtAmountAtom = atom({
  key: 'usdtAmount',
  default: (async () => queryInitialSettingValue('usdtAmount'))(),
})

export const usdtAmountSelector = selector({
  key: 'usdtAmount/selector',
  get: ({get}) => get(usdtAmountAtom),
  set: ({set}, newValue) => {
    set(usdtAmountAtom, newValue)
    updateSettingValue('usdtAmountAtom', newValue)
  }
})

export const tbAddressAtom = atom({
  key: 'tbAddress',
  default: (async () => queryInitialSettingValue('tbAddress'))(),
})

export const tbAddressSelector = selector({
  key: 'tbAddress/selector',
  get: ({get}) => get(tbAddressAtom),
  set: ({set}, newValue) => {
    set(tbAddressAtom, newValue)
    updateSettingValue('tbAddress', newValue)
  }
})

const cexBalanceAmountAtom = atom({
  key: 'cexBalanceAmount',
  default: (async () => queryInitialSettingValue('cexBalanceAmount'))(),
})

export const cexBalanceAmountSelector = selector({
  key: 'cexBalanceAmount/selector',
  get: ({get}) => get(cexBalanceAmountAtom),
  set: ({set}, newValue) => {
    set(cexBalanceAmountAtom, newValue)
    updateSettingValue('cexBalanceAmountAtom', newValue)
  }
})

const isEnabledNotifyingAtom = atom({
  key: 'isEnabledNotifying',
  default: (async () => queryInitialSettingValue('isEnabledNotifying'))(),
})

export const isEnabledNotifyingSelector = selector({
  key: 'isEnabledNotifying/selector',
  get: ({get}) => get(isEnabledNotifyingAtom),
  set: ({set}, newValue) => {
    set(isEnabledNotifyingAtom, newValue)
    updateSettingValue('isEnabledNotifyingAtom', newValue)
  }
})

const isWatchedDexToCexAtom = atom({
  key: 'isWatchedDexToCex',
  default: (async () => queryInitialSettingValue('isWatchedDexToCex'))(),
})

export const isWatchedDexToCexSelector = selector({
  key: 'isWatchedDexToCex/selector',
  get: ({get}) => get(isWatchedDexToCexAtom),
  set: ({set}, newValue) => {
    set(isWatchedDexToCexAtom, newValue)
    updateSettingValue('isWatchedDexToCexAtom', newValue)
  }
})

const isWatchedCexToDexAtom = atom({
  key: 'isWatchedCexToDex',
  default: (async () => queryInitialSettingValue('isWatchedCexToDex'))(),
})

export const isWatchedCexToDexSelector = selector({
  key: 'isWatchedCexToDex/selector',
  get: ({get}) => get(isWatchedCexToDexAtom),
  set: ({set}, newValue) => {
    set(isWatchedCexToDexAtom, newValue)
    updateSettingValue('isWatchedCexToDexAtom', newValue)
  }
})

const activeTabAtom = atom({
  key: 'activeTab',
  default: (async () => queryInitialSettingValue('activeTab'))(),
})

export const activeTabSelector = selector({
  key: 'activeTab/selector',
  get: ({get}) => get(activeTabAtom),
  set: ({set}, newValue) => {
    set(activeTabAtom, newValue)
    updateSettingValue('activeTab', newValue)
  }
})

const isEnableUpdateCoinInfoAtom = atom({
  key: 'isEnableUpdateCoinInfo',
  default: (async () => queryInitialSettingValue('isEnableUpdateCoinInfo'))(),
})

export const isEnableUpdateCoinInfoSelector = selector({
  key: 'isEnableUpdateCoinInfo/selector',
  get: ({get}) => get(isEnableUpdateCoinInfoAtom),
  set: ({set}, newValue) => {
    set(isEnableUpdateCoinInfoAtom, newValue)
    updateSettingValue('isEnableUpdateCoinInfo', newValue)
  }
})

const latestNotifiesSecondsAtom = atom({
  key: 'latestNotifiesSeconds',
  default: (async () => queryInitialSettingValue('latestNotifiesSeconds'))(),
})

export const latestNotifiesSecondsSelector = selector({
  key: 'latestNotifiesSeconds/selector',
  get: ({get}) => get(latestNotifiesSecondsAtom),
  set: ({set}, newValue) => {
    set(latestNotifiesSecondsAtom, newValue)
    updateSettingValue('latestNotifiesSeconds', newValue)
  }
})

const blockedSymbolsAtom = atom({
  key: 'blockedSymbols',
  default: (async () => queryInitialSettingValue('blockedSymbols'))(),
})

export const blockedSymbolsSelector = selector({
  key: 'blockedSymbols/selector',
  get: ({get}) => get(blockedSymbolsAtom),
  set: ({set}, newValue) => {
    set(blockedSymbolsAtom, newValue)
    updateSettingValue('blockedSymbols', newValue)
  }
})

const notifyAudioVolumeAtom = atom({
  key: 'notifyAudioVolume',
  default: (async () => queryInitialSettingValue('notifyAudioVolume'))(),
})

export const notifyAudioVolumeSelector = selector({
  key: 'notifyAudioVolume/selector',
  get: ({get}) => get(notifyAudioVolumeAtom),
  set: ({set}, newValue) => {
    set(notifyAudioVolumeAtom, newValue)
    updateSettingValue('notifyAudioVolume', newValue)
  }
})

const blockedValueOfFormulaAAtom = atom({
  key: 'blockedValueOfFormulaA',
  default: (async () => queryInitialSettingValue('blockedValueOfFormulaA'))(),
})

export const blockedValueOfFormulaASelector = selector({
  key: 'blockedValueOfFormulaA/selector',
  get: ({get}) => get(blockedValueOfFormulaAAtom),
  set: ({set}, newValue) => {
    set(blockedValueOfFormulaAAtom, newValue)
    updateSettingValue('blockedValueOfFormulaA', newValue)
  }
})

const blockedValueOfFormulaBAtom = atom({
  key: 'blockedValueOfFormulaB',
  default: (async () => queryInitialSettingValue('blockedValueOfFormulaB'))(),
})

export const blockedValueOfFormulaBSelector = selector({
  key: 'blockedValueOfFormulaB/selector',
  get: ({get}) => get(blockedValueOfFormulaBAtom),
  set: ({set}, newValue) => {
    set(blockedValueOfFormulaBAtom, newValue)
    updateSettingValue('blockedValueOfFormulaB', newValue)
  }
})


const enableBlockedValueOfFormulaAAtom = atom({
  key: 'enableBlockedValueOfFormulaA',
  default: (async () => queryInitialSettingValue('enableBlockedValueOfFormulaA'))(),
})

export const enableBlockedValueOfFormulaASelector = selector({
  key: 'enableBlockedValueOfFormulaA/selector',
  get: ({get}) => get(enableBlockedValueOfFormulaAAtom),
  set: ({set}, newValue) => {
    set(enableBlockedValueOfFormulaAAtom, newValue)
    updateSettingValue('enableBlockedValueOfFormulaA', newValue)
  }
})

const enableBlockedValueOfFormulaBAtom = atom({
  key: 'enableBlockedValueOfFormulaB',
  default: (async () => queryInitialSettingValue('enableBlockedValueOfFormulaB'))(),
})

export const enableBlockedValueOfFormulaBSelector = selector({
  key: 'enableBlockedValueOfFormulaB/selector',
  get: ({get}) => get(enableBlockedValueOfFormulaBAtom),
  set: ({set}, newValue) => {
    set(enableBlockedValueOfFormulaBAtom, newValue)
    updateSettingValue('enableBlockedValueOfFormulaB', newValue)
  }
})

const enableBlockedSymbolWithdrawalAtom = atom({
  key: 'enableBlockedSymbolWithdrawal',
  default: (async () => queryInitialSettingValue('enableBlockedSymbolWithdrawal'))(),
})

export const enableBlockedSymbolWithdrawalSelector = selector({
  key: 'enableBlockedSymbolWithdrawal/selector',
  get: ({get}) => get(enableBlockedSymbolWithdrawalAtom),
  set: ({set}, newValue) => {
    set(enableBlockedSymbolWithdrawalAtom, newValue)
    updateSettingValue('enableBlockedSymbolWithdrawal', newValue)
  }
})

const enableBlockedNoBalanceAtom = atom({
  key: 'enableBlockedNoBalance',
  default: (async () => queryInitialSettingValue('enableBlockedNoBalance'))(),
})

export const enableBlockedNoBalanceSelector = selector({
  key: 'enableNoBalanceWithdrawal/selector',
  get: ({get}) => get(enableBlockedNoBalanceAtom),
  set: ({set}, newValue) => {
    set(enableBlockedNoBalanceAtom, newValue)
    updateSettingValue('enableBlockedNoBalance', newValue)
  }
})

const enableBlockedSymbolDepositAtom = atom({
  key: 'enableBlockedSymbolDeposit',
  default: (async () => queryInitialSettingValue('enableBlockedSymbolDeposit'))(),
})

export const enableBlockedSymbolDepositSelector = selector({
  key: 'enableBlockedSymbolDeposit/selector',
  get: ({get}) => get(enableBlockedSymbolDepositAtom),
  set: ({set}, newValue) => {
    set(enableBlockedSymbolDepositAtom, newValue)
    updateSettingValue('enableBlockedSymbolDeposit', newValue)
  }
})
