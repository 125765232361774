import {useRecoilState} from 'recoil'
import {useMount, useTitle} from 'ahooks'
import {cexAtom, dexAtom} from '../states/exchangeState'

const useExchange = () => {

  const [cex, setCex] = useRecoilState(cexAtom)
  const [dex, setDex] = useRecoilState(dexAtom)

  const detectExchange = () => {
    let {host} = window.location
    const isDevEnv = host.includes('localhost')
    isDevEnv && (host = 'gate-1inch.listviewss.com')
    const url = new URL(window.location.href)
    const queryCex = url.searchParams.get('cex')
    const queryDex = url.searchParams.get('dex')
    let [cex, dex] = String(String(host).split('.')[0]).split('-')
    cex = queryCex || cex
    dex = queryDex || dex
    if (['huobi', 'binance', 'gate', 'mxc', 'kucoin'].includes(String(cex).toLowerCase())) {
      setCex(cex)
    }
    if (['1inch', 'pancake'].includes(String(dex).toLowerCase())) {
      setDex(dex)
    }
    if (queryDex) {
      setDex(queryDex)
    }
  }

  useTitle(`CoinMonitor ${(cex && dex) ? `@ ${cex || 'Cex'}&${dex || 'Dex'}` : ''}`)

  useMount(() => {
    detectExchange()
  })

  return {
    cex,
    dex,
  }

}

export default useExchange
