import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './virtual:windi.css'
import {RecoilRoot} from 'recoil'
import {Spin} from 'antd'

ReactDOM.render(
  <RecoilRoot>
    <React.Suspense fallback={<Spin tip="服务加载中..." size="large" className="bg-[rgba(255,255,255,.15)] py-8 px-4 rounded w-full text-white"/>}>
      <App/>
    </React.Suspense>
  </RecoilRoot>
  ,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
