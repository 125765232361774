import { useState } from 'react'
import io from 'socket.io-client'
import defaultSettings from '../config/settingConfig'
import {useRecoilValue} from 'recoil'
import { accessTokenSelector } from '../states/authState'
import { cexAtom, dexAtom, modeSelector } from '../states/exchangeState'

export function useSocket () {
  const [socket, setSocket] = useState(null)
  const [cex, dex, mode] = [useRecoilValue(cexAtom), useRecoilValue(dexAtom), useRecoilValue(modeSelector)]
  const accessToken = useRecoilValue(accessTokenSelector)
  const connect = () => {
    const protocol = 'wss://'
    const host = mode === 'ws' ? defaultSettings.wsServiceHost : defaultSettings.apiServiceHost
    const connection = io(protocol + host, {
      query: {
        cex,
        dex,
        accessToken
      }
    })
    setSocket(connection)
    return connection
  }
  const disconnect = () => {
    if (socket) {
      socket.disconnect()
      setSocket(null)
    }
  }
  return {
    connect,
    disconnect,
    io: socket
  }
}
