'use strict'

import defaultSettings from './settingConfig'

const apiServices = {
}

export default apiServices

export const socketServerURL = 'wss://' + defaultSettings.apiServiceHost
export const serverApiURI = 'https://' + defaultSettings.apiServiceHost
export const wsURI = 'https://' + defaultSettings.wsServiceHost
