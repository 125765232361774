import React from 'react'
import {message, Modal} from 'antd'
import {useRecoilState, useRecoilValue} from 'recoil'
import {isShowAddingModalAtom} from '../states/appState'
import {useReactive} from 'ahooks'
import fly from '../plugins/flyioPlugin'
import {serverApiURI} from '../config/apiConfig'
import {addLog} from '../services/dbService'
import {cexAtom, dexAtom} from '../states/exchangeState'
import {accessTokenSelector} from '../states/authState'

const AddingModalComponent = () => {
  const [isShowAddingModal, setIsShowAddingModal] = useRecoilState(isShowAddingModalAtom)
  const [cex, dex] = [useRecoilValue(cexAtom), useRecoilValue(dexAtom)]
  const accessToken = useRecoilValue(accessTokenSelector)

  const requestAddMonitorItem = async () => {
    if (!state.symbol || !state.token || !state.decimals) {
      message.error('添加信息不完善，请检查！')
      return
    }
    try {
      const response = await fly.request(serverApiURI + '/api/coin/add', {
        symbol: state.symbol,
        contract: state.token,
        decimals: state.decimals,
        cex,
        dex,
        accessToken
      }, {
        method: 'GET',
        parseJson: true,
      })
      addLog('新增监控', `添加 ${state.symbol} 成功`, {addingFrom: state, response})
      message.success('添加成功！重新启动服务后生效。')
    } catch (e) {
      addLog('新增监控', `添加 ${state.symbol} 失败`, {addingFrom: state, error: e})
    }
    setIsShowAddingModal(false)
  }

  const handleCancel = () => {
    setIsShowAddingModal(false)
  }

  const state = useReactive({
    symbol: '',
    decimals: '',
    token: ''
  })

  const formItems = [
    {valueField: 'symbol', placeholder: 'CoinName / Symbol'},
    {valueField: 'decimals', placeholder: 'Decimals'},
    {valueField: 'token', placeholder: 'TokenAddress / Contract'},
    // {valueField: 'amount', placeholder: 'USDT Amount'},
  ]

  return (
    <>
      <Modal
        title="添加监控项"
        visible={isShowAddingModal}
        onOk={requestAddMonitorItem}
        onCancel={handleCancel}
        cancelText="取消"
        okText="添加">
        <div className="space-y-4">
          {formItems.map((item) =>
            (
              <input
                key={item.valueField}
                className="px-3 py-2 w-full font-light bg-[rgba(255,255,255,.2)] border-none rounded text-white block"
                onChange={(e) => (state[item.valueField] = e.target.value)}
                placeholder={item.placeholder}/>
            )
          )}
        </div>
      </Modal>
    </>
  )

}

export default AddingModalComponent
