import React from 'react'
import audioFile from '../assets/notification.wav'
import {useUnmount, useUpdateEffect} from 'ahooks'
import {useRecoilValue} from 'recoil'
import {currentTimeAtom, latestNotifyTimestampAtom} from '../states/appState'
import dayjs from 'dayjs'
import {isEnabledNotifyingSelector, isEnabledRefreshingAtom, notifyAudioVolumeSelector} from '../states/settingState'

const NotificationAudioComponent = (props) => {

  let latestPlayAudioUnixTime = null
  const currentTime = useRecoilValue(currentTimeAtom)
  const isEnabledNotifying = useRecoilValue(isEnabledNotifyingSelector)
  const isEnabledRefreshing = useRecoilValue(isEnabledRefreshingAtom)
  const notifyAudioVolume = useRecoilValue(notifyAudioVolumeSelector)
  const latestNotifyTimestamp = useRecoilValue(latestNotifyTimestampAtom)
  const currentUnixTime = dayjs().unix()

  useUpdateEffect(() => {
    if (isEnabledNotifying && isEnabledRefreshing) {
      controlNotifyAudio(currentUnixTime - latestNotifyTimestamp > 5 ? 'stop' : 'play')
    } else {
      controlNotifyAudio('stop')
    }
  }, [currentTime])

  useUnmount(() => {
    controlNotifyAudio('pause')
  })

  useUpdateEffect(() => {
    if (notifyAudioVolume >= 0 && notifyAudioVolume <= 1) {
      controlNotifyAudio('setVolume', notifyAudioVolume)
    }
  }, [notifyAudioVolume])

  const controlNotifyAudio = (action = 'play', value = null) => {
    try {
      const player = document.getElementById('player')
      if (action === 'play') {
        player && player.play() && (latestPlayAudioUnixTime = currentUnixTime)
      }
      if (['pause', 'stop'].includes(action)) {
        player && player.pause()
      }
      if (action === 'setVolume' && value) {
        player && (player.volume = value)
      }
    } catch (e) {
      console.log(e.message)
    }
  }

  return (
    <>
      <audio
        id="player"
        loop
        // controls="controls"
      >
        <source src={audioFile} type="audio/x-wav" />
        <embed height="100" width="100" src={audioFile} />
      </audio>
    </>
  )
}

export default NotificationAudioComponent
