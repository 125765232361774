import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'

dayjs.locale('zh-cn')
dayjs.extend(updateLocale)
dayjs.extend(relativeTime)
dayjs.updateLocale('zh-cn', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: '%d秒前',
    m: '%d分前',
    mm: '%d分前',
    h: 'an hour',
    hh: '%d hours',
    d: '%d天前',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years'
  }
})

export default dayjs
