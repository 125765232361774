import React, {useState} from 'react'
import {Input, message, Slider, Switch, Tooltip, Button, Space} from 'antd'
import {useRecoilState, useRecoilValue} from 'recoil'
import fly from '../plugins/flyioPlugin'

import {
  activeTabSelector,
  blockedValueOfFormulaASelector,
  blockedValueOfFormulaBSelector,
  cexBalanceAmountSelector,
  isEnabledNotifyingSelector,
  isEnabledRefreshingAtom,
  isWatchedCexToDexSelector,
  isWatchedDexToCexSelector,
  isWsRunningAtom,
  notifyAudioVolumeSelector,
  notifyValueOfFormulaASelector,
  notifyValueOfFormulaBSelector,
  tbAddressSelector,
  usdtAmountSelector
} from '../states/settingState'
import {cexAtom, dexAtom, modeSelector} from '../states/exchangeState'
import {authUserSelector} from '../states/authState'
import {wsURI} from '../config/apiConfig'

const SettingPanelComponent = () => {

  const [loading, setLoading] = useState(false)
  const [tbAddress, setTbAddress] = useRecoilState(tbAddressSelector)
  const [usdtAmount, setUsdtAmount] = useRecoilState(usdtAmountSelector)
  const [cexBalanceAmount, setCexBalanceAmount] = useRecoilState(cexBalanceAmountSelector)
  const exchangeMode = useRecoilValue(modeSelector)
  const [isEnabledNotifying, setIsEnabledNotifying] = useRecoilState(isEnabledNotifyingSelector)
  const [isWsRunning, setIsWsRunning] = useRecoilState(isWsRunningAtom)
  const [isEnabledRefreshing, setIsEnabledRefreshing] = useRecoilState(isEnabledRefreshingAtom)
  const [isWatchedDexToCex, setIsWatchedDexToCex] = useRecoilState(isWatchedDexToCexSelector)
  const [isWatchedCexToDex, setIsWatchedCexToDex] = useRecoilState(isWatchedCexToDexSelector)
  const [notifyValueOfFormulaA, setNotifyValueOfFormulaA] = useRecoilState(notifyValueOfFormulaASelector)
  const [notifyValueOfFormulaB, setNotifyValueOfFormulaB] = useRecoilState(notifyValueOfFormulaBSelector)
  const [blockedValueOfFormulaA, setBlockedValueOfFormulaA] = useRecoilState(blockedValueOfFormulaASelector)
  const [blockedValueOfFormulaB, setBlockedValueOfFormulaB] = useRecoilState(blockedValueOfFormulaBSelector)
  const [notifyAudioVolume, setNotifyAudioVolume] = useRecoilState(notifyAudioVolumeSelector)
  const [cex, dex] = [useRecoilValue(cexAtom), useRecoilValue(dexAtom)]
  const authUser = useRecoilValue(authUserSelector)

  const handleServiceSwitch = async (checked) => {
    setLoading(true)
    try {
      if (checked) {
        await start()
      } else {
        await close()
      }
    } catch {
      console.log('error')
    }
    setLoading(false)
  }

  const start = async () => {
    const res = await fly.get(wsURI + '/api/start', {
      cex,
      dex,
      amount: usdtAmount
    })
    if (res.code === 0) {
      setIsWsRunning(true)
      setIsEnabledRefreshing(true)
      message.success('服务已启动')
    } else {
      message.error(`启动服务失败: ${res.error}`)
    }
  }

  const close = async () => {
    await fly.get(wsURI + '/api/close', {
      cex,
      dex,
    })
    message.success('服务已关闭')
    setIsWsRunning(false)
    setIsEnabledRefreshing(false)
  }

  return (
    <div
      className="ant-input-control-area bg-[rgba(255,255,255,.1)] px-4 py-6 w-full text-white space-y-4 rounded-md transition-all duration-100 hover:bg-opacity-15 hover:shadow-xl">
      <div className="space-x-2 flex flex-row flex-wrap <md:space-y-2">
        {authUser.type === 'master' && <Switch checkedChildren="服务启动" unCheckedChildren="服务停止" checked={!!isWsRunning} loading={loading} onChange={handleServiceSwitch}/>}
        <Switch checkedChildren="监控启动" unCheckedChildren="监控停止" checked={isEnabledRefreshing}
          onChange={(checked) => setIsEnabledRefreshing(checked)} defaultChecked/>
        <Switch checkedChildren="报警开启" unCheckedChildren="报警关闭" defaultChecked={isEnabledNotifying}
          onChange={(checked) => setIsEnabledNotifying(checked)} />
        {/* <Tooltip title="开启混合更新模式会在初始化时从HTTP接口获取所有quotes数据进行首次更新，关闭后只从Socket中监听获取数据。" size="large">*/}
        {/*  <Switch checkedChildren="混合更新开" unCheckedChildren="混合更新关" defaultChecked={isEnabledMixedUpdatingMode}*/}
        {/*    onChange={(checked) => setIsEnabledMixedUpdatingMode(checked)} />*/}
        {/* </Tooltip>*/}
        <Switch checkedChildren={`${dex} > ${cex}`} unCheckedChildren={`${dex} > ${cex}`} defaultChecked={isWatchedDexToCex}
          onChange={(checked) => setIsWatchedDexToCex(checked)}/>
        <Switch checkedChildren={`${cex} > ${dex}`} unCheckedChildren={`${cex} > ${dex}`} defaultChecked={isWatchedCexToDex}
          onChange={(checked) => setIsWatchedCexToDex(checked)}/>
      </div>
      <div
        className="
        space-x-2 flex flex-row flex-nowrap opacity-70 transition-all duration-100 ease-in transform
        hover:opacity-100
        <md:space-x-0 <md:space-y-2 <md:flex-col
        ">
        <Tooltip title="公式A报警阈值，请填入数值（可包含小数）" size="large">
          <Input
            type="text"
            className={isEnabledRefreshing && 'cursor-not-allowed pointer-events-none'}
            prefix="公式A报警阈值"
            defaultValue={notifyValueOfFormulaA}
            onBlur={(e) => setNotifyValueOfFormulaA(Number(e.target.value))}
          />
        </Tooltip>
        <Tooltip title="公式A屏蔽阈值，请填入数值（可包含小数）" size="large">
          <Input
            type="text"
            prefix="公式A屏蔽阈值"
            defaultValue={blockedValueOfFormulaA}
            onBlur={(e) => setBlockedValueOfFormulaA(Number(e.target.value))}
          />
        </Tooltip>
        <Tooltip title="公式B报警阈值，请填入数值（可包含小数）" size="large">
          <Input
            type="text"
            className={isEnabledRefreshing && 'cursor-not-allowed pointer-events-none'}
            prefix="公式B报警阈值"
            defaultValue={notifyValueOfFormulaB}
            onChange={(e) => setNotifyValueOfFormulaB(Number(e.target.value))}
          />
        </Tooltip>
        <Tooltip title="公式B屏蔽阈值，请填入数值（可包含小数）" size="large">
          <Input
            type="text"
            prefix="公式B屏蔽阈值"
            defaultValue={blockedValueOfFormulaB}
            onBlur={(e) => setBlockedValueOfFormulaB(Number(e.target.value))}
          />
        </Tooltip>
        <Tooltip title={`USDT总额当前为${usdtAmount}`} size="large">
          <Input
            type="text"
            className={isEnabledRefreshing && 'cursor-not-allowed pointer-events-none'}
            readOnly={authUser.type !== 'master' || isWsRunning}
            prefix="USDT 总额"
            defaultValue={usdtAmount || 0}
            value={usdtAmount}
            onChange={(e) => setUsdtAmount(Number(e.target.value))}
          />
        </Tooltip>
        <Tooltip size="large">
          <Input
            type="text"
            className={isEnabledRefreshing && 'cursor-not-allowed pointer-events-none'}
            prefix="余额报警 总额"
            placeholder='使用USDT总额'
            value={cexBalanceAmount}
            onChange={(e) => setCexBalanceAmount(e.target.value)}
          />
        </Tooltip>
      </div>
      <div>

      </div>
      <div className={`hidden space-x-2 flex flex-row flex-nowrap opacity-70 transition-all duration-100 ease-in transform 
        hover:opacity-100 
        <md:space-x-0 <md:space-y-2 <md:flex-col
          ${useRecoilValue(activeTabSelector) === 'setting' && '!flex'}
        `}>
        <div className="w-full">
          <Input
            type="text"
            prefix="提币地址"
            placeholder='提币地址'
            value={tbAddress}
            onChange={(e) => setTbAddress(e.target.value)}
          />
        </div>
      </div>
      <div className={`hidden space-x-2 flex flex-row flex-nowrap opacity-70 transition-all duration-100 ease-in transform 
        hover:opacity-100 
        <md:space-x-0 <md:space-y-2 <md:flex-col
          ${useRecoilValue(activeTabSelector) === 'setting' && '!flex'}
        `}>
        <div className="w-full">
          <Tooltip title="设置报警声音音量 0（静音）~1（最大声）" size="large">
            <span className="text-white">报警音量</span>
            <Slider
              min={0}
              max={1}
              onChange={(value) => setNotifyAudioVolume(value)}
              defaultValue={notifyAudioVolume}
              value={notifyAudioVolume}
              step={0.01}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default SettingPanelComponent
