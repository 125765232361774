import {atom, selector} from 'recoil'
import dayjs from '../plugins/dayjsPlugin'

export const launchTimeAtom = atom({
  key: 'launchTime',
  default: dayjs().format('YYYY-MM-DD HH:mm:ss'),
})

export const currentTimeAtom = atom({
  key: 'currentTime',
  default: dayjs().format('YYYY-MM-DD HH:mm:ss'),
})

export const latestNotifyTimestampAtom = atom({
  key: 'latestNotifyTimestamp',
  default: null
})

export const runningTimeSelector = selector({
  key: 'runningTime',
  get: ({get}) => {
    const unit = {
      hours: dayjs(get(currentTimeAtom)).diff(dayjs(get(launchTimeAtom)), 'hour'),
      minutes: dayjs(get(currentTimeAtom)).diff(dayjs(get(launchTimeAtom)), 'minute'),
      seconds: dayjs(get(currentTimeAtom)).diff(dayjs(get(launchTimeAtom)), 'second')
    }
    const runningTimeText = []
    unit.hours > 0 && runningTimeText.push(`${unit.hours} 时`)
    unit.minutes > 0 && runningTimeText.push(`${unit.minutes < 60 ? unit.minutes : Math.floor(unit.minutes % 60)} 分`)
    unit.seconds > 0 && runningTimeText.push(`${unit.seconds < 60 ? unit.seconds : Math.floor(unit.seconds % 60)} 秒`)
    return runningTimeText.join('')
  }
})

export const updatingLockerAtom = atom({
  key: 'updatingLocker',
  default: false,
})

export const gasAtom = atom({
  key: 'gas',
  default: null,
})

export const ethAtom = atom({
  key: 'eth',
  default: null,
})

export const isShowAddingModalAtom = atom({
  key: 'isShowAddingModal',
  default: false,
})

export const isShowMonitorDetailModalAtom = atom({
  key: 'isShowMonitorDetailModal',
  default: false,
})

export const systemModeAtom = atom({
  key: 'systemModeAtom',
  default: false,
})
