export const toNonExponentialNumber = (numString) => {
  let i
  numString = numString.toString()
  if (numString.indexOf('+') !== -1) {
    numString = numString.replace('+', '')
  }
  if (numString.indexOf('E') !== -1 || numString.indexOf('e') !== -1) {
    let resValue = '',
      power = '',
      result = null,
      dotIndex = 0,
      resArr = [],
      sym = ''
    let numStr = numString.toString()
    if (numStr[0] === '-') {
      // 如果为负数，转成正数处理，先去掉‘-’号，并保存‘-’.
      numStr = numStr.substr(1)
      sym = '-'
    }
    if (numStr.indexOf('E') !== -1 || numStr.indexOf('e') !== -1) {
      let regExp = new RegExp(
        '^(((\\d+.?\\d+)|(\\d+))[Ee]{1}((-(\\d+))|(\\d+)))$',
        'ig'
      )
      result = regExp.exec(numStr)
      if (result != null) {
        resValue = result[2]
        power = result[5]
        result = null
      }
      if (!resValue && !power) {
        return false
      }
      dotIndex = resValue.indexOf('.') === -1 ? 0 : resValue.indexOf('.')
      resValue = resValue.replace('.', '')
      resArr = resValue.split('')
      if (Number(power) >= 0) {
        let subres = resValue.substr(dotIndex)
        power = Number(power)
        // 幂数大于小数点后面的数字位数时，后面加0
        for (i = 0; i <= power - subres.length; i++) {
          resArr.push('0')
        }
        if (power - subres.length < 0) {
          resArr.splice(dotIndex + power, 0, '.')
        }
      } else {
        power = power.replace('-', '')
        power = Number(power)
        // 幂数大于等于 小数点的index位置, 前面加0
        for (i = 0; i < power - dotIndex; i++) {
          resArr.unshift('0')
        }
        let n = power - dotIndex >= 0 ? 1 : -(power - dotIndex)
        resArr.splice(n, 0, '.')
      }
    }
    resValue = resArr.join('')

    return sym + resValue
  } else {
    return numString
  }
}

export const getDecimalsOfNumber = (numString) => {
  if (numString && String(numString).includes('.') > 0) {
    return String(numString).split('.')[1].length
  }
  return 0
}
