import React from 'react'
import './styles/app.scss'
import useApp from './hooks/useApp'
import {useRecoilValue} from 'recoil'
import useExchange from './hooks/useExchange'
import {isExchangeReadySelector} from './states/exchangeState'
import ChooseExchangeComponent from './components/ChooseExchange'
import MainComponent from './components/Main'
import useMonitor from './hooks/useMonitor'
import {isAuthedSelector} from './states/authState'
import UserLoginComponent from './components/UserLogin'
import { ErrorBoundary } from 'react-error-boundary'
import { Result } from 'antd'

const App = () => {

  useApp()
  useExchange()
  const { tokens } = useMonitor()

  const isExchangeReady = useRecoilValue(isExchangeReadySelector)
  const isAuthed = useRecoilValue(isAuthedSelector)
  const ErrorFallback = (props) => {
    const { error } = props
    return <Result title={error.message} subTitle={error.stack} />
  }
  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {!isAuthed
          ? (<UserLoginComponent/>)
          : (!isExchangeReady
            ? (<ChooseExchangeComponent />)
            : (<MainComponent tokens={tokens}/>))
        }
      </ErrorBoundary>
    </>
  )
}

export default App
