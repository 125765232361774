import {atom, selector} from 'recoil'
import {queryInitialSettingValue, updateSettingValue} from '../services/dbService'
import jwt from 'jsonwebtoken'

export const usernameAtom = atom({
  key: 'username',
  default: null,
})

export const passwordAtom = atom({
  key: 'password',
  default: null,
})

const accessTokenAtom = atom({
  key: 'accessToken',
  default: (async () => queryInitialSettingValue('accessToken'))(),
})

export const accessTokenSelector = selector({
  key: 'accessToken/selector',
  get: ({get}) => get(accessTokenAtom),
  set: ({set}, newValue) => {
    set(accessTokenAtom, newValue)
    updateSettingValue('accessToken', newValue)
  }
})

const expireTimestampAtom = atom({
  key: 'expireTimestamp',
  default: (async () => queryInitialSettingValue('expireTimestamp'))(),
})

export const expireTimestampSelector = selector({
  key: 'expireTimestamp/selector',
  get: ({get}) => get(expireTimestampAtom),
  set: ({set}, newValue) => {
    set(expireTimestampAtom, newValue)
    updateSettingValue('expireTimestamp', newValue)
  }
})

export const isAuthedSelector = selector({
  key: 'isAuthed/selector',
  get: ({get}) => (
    get(accessTokenAtom) && (get(expireTimestampAtom) > (new Date().getTime() / 1000))
  )
})

export const authUserSelector = selector({
  key: 'authUser/selector',
  get: ({get}) => (
    jwt.decode(get(accessTokenAtom))
  )
})
